import { BrowserAuthError } from '@azure/msal-browser';
import { captureException, setExtra, setTags } from '@sentry/react';
import ky, { HTTPError } from 'ky';

import { API } from '~/config/apiConfig';
import { instance } from './instance';

export class ApiError extends Error {
  code: number;
  message: string;
  data: unknown;

  constructor(message: string, code: number, url: string, data: unknown) {
    super(message);
    this.message = message;
    this.code = code;
    this.data = data;
    this.name = 'ApiError';

    setTags({
      error_code: code,
      request_url: url,
    });

    if (data && typeof data !== 'string') {
      setExtra('error', data);
    }

    captureException(this);
  }
}

class NetworkError extends Error {
  constructor(message: string, url: string) {
    super(message);
    this.name = 'NetworkError';

    setTags({
      request_url: url,
    });

    captureException(this);
  }
}

class SyntaxError extends Error {
  constructor(message: string, url: string) {
    super(message);
    this.name = 'SyntaxError';

    setTags({
      request_url: url,
    });

    captureException(this);
  }
}

class TokenError extends Error {
  constructor(message: string, url: string) {
    super(message);
    this.name = 'TokenError';

    setTags({
      request_url: url,
    });

    captureException(this);
  }
}

export const api = ky.extend({
  retry: 0,
  timeout: 30000,
  fetch: async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    try {
      return await fetch(input, init);
    } catch (error: any) {
      if (input instanceof Request) {
        if (!error.response) {
          throw new NetworkError(error, input.url);
        } else {
          throw new SyntaxError(error, input.url);
        }
      }

      throw error;
    }
  },
  hooks: {
    beforeRequest: [
      async request => {
        const apiConfig = API;
        const requestedApi = Object.values(apiConfig).find(api => request.url.startsWith(api.url));

        const scopes = requestedApi ? [requestedApi.scope] : [];

        if (scopes.length) {
          try {
            const token = await instance.acquireTokenSilent({ scopes });
            request.headers.set('Authorization', 'Bearer ' + token.accessToken);
          } catch (error) {
            if (error instanceof BrowserAuthError) {
              console.error(
                `Unable to acquire token for request: ${request.url}. Error: ${error.message}`,
              );
            }

            throw new TokenError('Unable to acquire token for request', request.url);
          }
        }

        return request;
      },
    ],
    beforeError: [
      async error => {
        const { response } = error;

        if (error instanceof HTTPError) {
          let errorResponseBody: null | any = null;

          if (response.body) {
            try {
              errorResponseBody = await response.clone().json();
            } catch (error) {
              errorResponseBody = await response.clone().text();
            }
          }

          const errorData =
            errorResponseBody?.data || errorResponseBody?.detail || errorResponseBody;

          throw new ApiError(error.message, response.status, response.url, errorData);
        }

        return error;
      },
    ],
  },
});
