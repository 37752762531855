import { Accordion, Grid, Input, Message } from '@intility/bifrost-react';
import { useTranslation } from 'react-i18next';

import { useMessageSenders } from '~/features/create';
import { useCompany } from '../api/getCompany';
import { AliasInput } from './AliasInput';

export const SenderAccordion = () => {
  const { t } = useTranslation('settings');

  const messageSender = useMessageSenders();
  const company = useCompany();

  return (
    <Accordion>
      <Accordion.Item defaultActive title={t('Sender')} className='border border-bfc-base-c-dimmed'>
        <Grid>
          <p>
            {t(
              'Some countries do not support names as the sender. In those cases the phone number below will appear instead.',
            )}
            {/* {t(
              'This section allows you to change the name of the sender. This change will apply to everyone sending messages on behalf of your company. Some countries do not support names as the sender. In those cases the phone number below will appear instead.',
            )} */}
          </p>
          <Message>
            {t('If you wish to change the sender name displayed on SMS messages, contact')}{' '}
            <a
              className='bf-link'
              target='_blank'
              href='https://my.intility.com/support'
              rel='noreferrer'
            >
              support
            </a>
          </Message>
          <Grid medium={3}>
            <Input
              label={t('Company')}
              value={company.data?.companyName ?? ''}
              disabled
              loading={company.isPending}
            />

            <Input
              label={t('Phone number')}
              value={messageSender.data?.phoneNumber ?? ''}
              disabled
              loading={messageSender.isLoading}
            />

            <AliasInput />
          </Grid>
        </Grid>
      </Accordion.Item>
    </Accordion>
  );
};
