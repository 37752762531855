import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Button, Grid, Input } from '@intility/bifrost-react';
import { useTranslation } from 'react-i18next';

import { useDebouncedQuery } from '~/hooks/useDebouncedQuery';
import { EnumParam, StringParam } from '~/lib/use-query-params';

export const HistoryParamConfig = {
  search: StringParam,
  tab: EnumParam(['all', 'me']),
};

export const HistoryOptions = () => {
  const { t } = useTranslation('history');

  const { debouncedSetQuery, query } = useDebouncedQuery(HistoryParamConfig);

  const selectedTab = query.tab ?? 'all';

  return (
    <Grid small={2} medium={3}>
      <Input
        className={'[&_input]:rounded-md'}
        placeholder={t('Filter')}
        label='Filter'
        hideLabel
        icon={faSearch}
        defaultValue={query.search ?? ''}
        onChange={e => debouncedSetQuery({ search: e.target.value || undefined })}
      />

      <div>
        <Button.Group className={'rounded-md'}>
          {[
            { label: t('All'), value: 'all' as const },
            { label: t('Sent by me'), value: 'me' as const },
          ].map(item => (
            <Button
              className={'!rounded-md'}
              key={item.value}
              active={selectedTab === item.value}
              onClick={() =>
                debouncedSetQuery({
                  tab: item.value === 'me' ? 'me' : undefined,
                })
              }
            >
              {item.label}
            </Button>
          ))}
        </Button.Group>
      </div>
    </Grid>
  );
};
