import { Table as BfTable, Grid, Pagination, Skeleton } from '@intility/bifrost-react';
import type { BreakpointValue } from '@intility/bifrost-react/types/components/Utility/Breakpoint/Breakpoint';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnDef,
  type ColumnFiltersState,
  type InitialTableState,
  type OnChangeFn,
  type PaginationState,
  type Row,
  type RowData,
  type SortingState,
  type TableMeta,
  type TableState,
} from '@tanstack/react-table';
import { useMemo, type JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '~/utils/clsx';

const sortDirections = { asc: 'asc', desc: 'desc' } as const;

declare module '@tanstack/table-core' {
  interface TableMeta<TData extends RowData> {
    /**
     * Displays skeleton rows if true
     */
    isLoading?: boolean;
    /**
     * Amount of skeleton rows to display. Defaults to the page size.
     */
    skeletonRows?: number;
    /**
     * Disable padding for expanded rows
     */
    noExpandPadding?: boolean;
    customLastRow?: JSX.Element;
    customNoResult?: string;
    placeholder?: string;
    noBorder?: boolean;
  }

  interface ColumnMeta<TData extends RowData, TValue> {
    /**
     * The breakpoint the column should start appearing at
     */
    fromSize?: BreakpointValue | null;
    /**
     * Class names to set on the cell
     */
    className?: string;
    /**
     * Class names to set on the header
     */
    headerClassName?: string;
  }
}

export type TDataWithSubRows<TData> = TData & {
  subRows?: TData[];
};

type BaseReactTableProps<TData> = {
  data: TDataWithSubRows<TData>[] | undefined;
  columns: ColumnDef<TData, any>[];
  enableColumnFilters?: boolean;
  enableGlobalFilter?: boolean;
  enableSorting?: boolean;
  enableExport?: boolean;
  manualFiltering?: boolean;
  manualSorting?: boolean;
  onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>;
  onGlobalFilterChange?: OnChangeFn<string>;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onSortingChange?: OnChangeFn<SortingState>;
  initialState?: InitialTableState;
  state?: Partial<TableState>;
  className?: string;
  /**
   * Components to display when expanding a row
   */
  SubRowComponent?: (props: { subRow: Row<TData> }) => React.ReactElement;
  ExpandedRowComponent?: (props: { rowData: TData }) => React.ReactElement;
  meta?: TableMeta<TData>;
};

type PaginationProps =
  | {
      /**
       * Manual pagination should be used when pagination is done server side.
       */
      manualPagination?: undefined;
      pageCount?: undefined;
    }
  | {
      manualPagination: true;
      /**
       * The number of pages the table should have.
       */
      pageCount: number;
    };

type ReactTableProps<TData> = BaseReactTableProps<TData> & PaginationProps;

export const Table = <TData,>({
  data,
  columns,
  enableExport,
  enableSorting,
  manualFiltering,
  manualPagination,
  manualSorting,
  onColumnFiltersChange,
  onGlobalFilterChange,
  onPaginationChange,
  onSortingChange,
  initialState,
  state,
  pageCount,
  className,
  SubRowComponent,
  ExpandedRowComponent,
  meta,
}: ReactTableProps<TData>) => {
  const { t } = useTranslation();

  // When using manual pagination we don't know the total number of pages until the data has been fetched, so we temporarily set it to 0
  if (manualPagination && !pageCount) {
    pageCount = 0;
  }

  const enableColumnFilters = initialState?.columnFilters ?? state?.columnFilters;
  const enableGlobalFilter = initialState?.globalFilter ?? state?.globalFilter;
  const enableColumnSorting =
    enableSorting ?? initialState?.sorting ?? state?.sorting ?? manualSorting;
  const enablePagination = initialState?.pagination ?? state?.pagination;
  const enableExpanding = Boolean(ExpandedRowComponent && data?.length);

  const table = useReactTable({
    data: useMemo(() => data ?? [], [data]),
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: enableExpanding ? getExpandedRowModel() : undefined,
    getFilteredRowModel:
      enableGlobalFilter || enableColumnFilters ? getFilteredRowModel() : undefined,
    getPaginationRowModel: enablePagination ? getPaginationRowModel() : undefined,
    getSortedRowModel: enableColumnSorting ? getSortedRowModel() : undefined,
    manualPagination,
    manualFiltering,
    manualSorting,
    ...(onColumnFiltersChange && { onColumnFiltersChange }),
    ...(onGlobalFilterChange && { onGlobalFilterChange }),
    ...(onPaginationChange && { onPaginationChange }),
    ...(onSortingChange && { onSortingChange }),
    getSubRows: (originalRow: any, _index) => originalRow.subRows,
    initialState,
    pageCount: manualPagination ? pageCount : undefined,
    state,
    meta,
    autoResetPageIndex: false,
  });

  const hasSubRows = table.getRowModel().rows.some(row => row.subRows.length);

  return (
    <Grid>
      <BfTable className={cn(className)} noBorder={meta?.noBorder}>
        <BfTable.Header>
          {table.getHeaderGroups().map(headerGroup => (
            <BfTable.Row key={headerGroup.id}>
              {(enableExpanding || hasSubRows) && !meta?.isLoading && <BfTable.HeaderCell />}

              {headerGroup.headers.map(header => {
                const isSortable = enableColumnSorting && header.column.getCanSort();
                const isSorted = header.column.getIsSorted();
                const sortDirection = !isSorted ? 'none' : sortDirections[isSorted];
                const metaData = header.column.columnDef.meta;
                const sizeClassName = metaData?.fromSize;

                return (
                  <BfTable.HeaderCell
                    key={header.id}
                    sorting={isSortable ? sortDirection : undefined}
                    onClick={isSortable ? header.column.getToggleSortingHandler() : undefined}
                    className={cn(sizeClassName && `from-${sizeClassName}`)}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </BfTable.HeaderCell>
                );
              })}
            </BfTable.Row>
          ))}
        </BfTable.Header>

        <BfTable.Body>
          {meta?.isLoading ? (
            <Skeleton repeat={meta.skeletonRows ?? initialState?.pagination?.pageSize ?? 10}>
              {table.getHeaderGroups().map(headerGroup => (
                <BfTable.Row key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    const metaData = header.column.columnDef.meta;
                    const cellClassName = metaData?.className;
                    const sizeClassName = metaData?.fromSize;

                    return (
                      <BfTable.Cell
                        key={header.id}
                        className={cn(
                          cellClassName && cellClassName,
                          sizeClassName && `from-${sizeClassName}`,
                        )}
                      >
                        <Skeleton.Text />
                      </BfTable.Cell>
                    );
                  })}
                </BfTable.Row>
              ))}
            </Skeleton>
          ) : (
            <>
              {table.getRowModel().rows.length ? (
                <>
                  {table.getRowModel().rows.map(row => {
                    const subRows = row.subRows;

                    return (
                      <BfTable.Row
                        onOpenChange={() => row.toggleExpanded()}
                        open={row.getIsExpanded()}
                        key={row.id}
                        content={
                          subRows.length && SubRowComponent ? (
                            <table className='bf-table'>
                              <tbody>
                                {subRows.map(subRow => (
                                  <SubRowComponent key={subRow.id} subRow={subRow} />
                                ))}
                              </tbody>
                            </table>
                          ) : ExpandedRowComponent ? (
                            <div className={cn(meta?.noExpandPadding ? '' : 'bfl-padding')}>
                              <ExpandedRowComponent rowData={row.original} />
                            </div>
                          ) : null
                        }
                        limitExpandClick
                      >
                        {!subRows.length && hasSubRows ? (
                          <td className='bf-table-expand-closed' />
                        ) : null}

                        {row.getVisibleCells().map(cell => {
                          const metaData = cell.column.columnDef.meta;
                          const cellClassName = metaData?.className;
                          const sizeClassName = metaData?.fromSize;

                          return (
                            <BfTable.Cell
                              key={cell.id}
                              className={cn(
                                cellClassName,
                                sizeClassName && `from-${sizeClassName}`,
                              )}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </BfTable.Cell>
                          );
                        })}
                      </BfTable.Row>
                    );
                  })}
                </>
              ) : (
                <BfTable.Row>
                  {meta?.placeholder ? (
                    <BfTable.Cell colSpan={25}>
                      <div className='flex items-center justify-center'>{meta.placeholder}</div>
                    </BfTable.Cell>
                  ) : (
                    <BfTable.Cell colSpan={25} className='bg-bfc-base-dimmed'>
                      <div className='flex items-center justify-center'>
                        {meta?.customNoResult ?? t('No results found')}
                      </div>
                    </BfTable.Cell>
                  )}
                </BfTable.Row>
              )}
            </>
          )}

          {data?.length && meta?.customLastRow ? (
            <BfTable.Row>
              <BfTable.Cell colSpan={25} className='bg-bfc-base-dimmed'>
                <div className={'flex items-center'}>{meta.customLastRow}</div>
              </BfTable.Cell>
            </BfTable.Row>
          ) : null}
        </BfTable.Body>
      </BfTable>

      <div className='flex items-center'>
        {enablePagination && (
          <Pagination
            totalPages={table.getPageCount()}
            currentPage={table.getState().pagination.pageIndex + 1}
            onChange={newPageNumber => table.setPageIndex(newPageNumber - 1)}
            className={cn(enableExport ? 'ml-auto' : 'mx-auto')}
          />
        )}
      </div>
    </Grid>
  );
};
