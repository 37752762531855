import { Input, useFloatingMessage } from '@intility/bifrost-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useMessageSenders } from '~/features/create';
import { useUpdateMessageSender } from '../api/updateMessageSender';

export const AliasInput = () => {
  const { t } = useTranslation('settings');
  const { showFloatingMessage } = useFloatingMessage();

  const updateMessageSender = useUpdateMessageSender();
  const messageSender = useMessageSenders();

  const [alias, setAlias] = useState('');

  const aliasSchema = z
    .string()
    .min(3, t('The sender name must be at least 3 characters long'))
    .max(11, t('The sender name cannot be longer than 11 characters'))
    .regex(/^[A-Za-z0-9\\_\\&\\+\\-]+$/, t('The sender name consists of invalid characters'));

  const aliasValidation = aliasSchema.safeParse(alias);

  const handleUpdateMessageSender = () => {
    if (!aliasValidation.success) {
      return showFloatingMessage(aliasValidation.error.errors[0]?.message, {
        state: 'warning',
      });
    }

    updateMessageSender.mutate(
      {
        twilioMessagingServiceSid: messageSender.data?.twilioMessagingServiceSid ?? '',
        inputDto: {
          alphaSender: alias,
        },
      },
      {
        onSuccess: () => {
          showFloatingMessage(t('Sender name updated successfully'), { state: 'success' });
        },
        onError: () => {
          showFloatingMessage(t('Failed to update the sender name. Please try again'), {
            state: 'alert',
          });
        },
      },
    );
  };

  return (
    <div className='flex flex-col'>
      <Input
        label={t('Sender name')}
        value={alias}
        state={!aliasValidation.success && alias.length ? 'alert' : 'default'}
        onChange={e => setAlias(e.target.value)}
        disabled
        placeholder={messageSender.data?.alphanumericSender ?? ''}
        loading={messageSender.isPending}
        feedback={
          aliasValidation.success || !alias.length ? '' : aliasValidation.error.errors[0]?.message
        }
      />

      {/* <div className='mt-bfs-8 text-bfc-base-c-2'>
        <p>{t('The sender name may consist of:')}</p>
        <ul className='ml-bfs-16 list-disc'>
          <li>3-11 {t('characters')}</li>
          <li>{t('The letters')} Aa-Zz</li>
          <li>{t('The numbers')} 0-9</li>
          <li>{t('Spaces')}</li>
          <li>{t('The special characters')} + - _ &</li>
        </ul>
      </div>

      <Button
        variant={'filled'}
        className='ml-auto mt-bfs-24 justify-self-end !rounded-xl'
        onClick={handleUpdateMessageSender}
        state={aliasValidation.success ? 'default' : 'inactive'}
      >
        {t('Confirm change')}
      </Button> */}
    </div>
  );
};
