export const GradientBackground = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={'h-screen bg-lightBackground bg-cover bg-center dark:bg-darkBackground'}>
      <div
        style={{
          backgroundImage:
            'radial-gradient(circle closest-side, transparent 30%, var(--bfc-base-1) 100%)',
        }}
        className={'absolute inset-0 h-full w-full'}
      />
      {children}
    </div>
  );
};
