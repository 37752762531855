interface MessageBubbleProps {
  messageBody: string;
}

export const MessageBubble = ({ messageBody }: MessageBubbleProps) => {
  return (
    <div className={'bf-scrollbar ml-auto flex items-center overflow-y-auto'}>
      <div
        className={
          'min-w-[150px] break-all rounded-[20px] bg-bfc-theme-3 p-4 text-sm text-bfc-base-3 sm:min-w-[300px]'
        }
      >
        {messageBody.split('\n').map((line, index) => {
          return line ? <p key={index}>{line}</p> : <br key={index} />;
        })}
      </div>

      <div
        className={
          'h-bfs-16 w-bfs-8 border-b-8 border-l-8 border-t-8 border-b-transparent border-l-bfc-theme-3 border-t-transparent'
        }
      />
    </div>
  );
};
