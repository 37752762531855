import type { MessageDeliveryDto } from '@intility/portal-sms-shared-utils';
import { useQuery } from '@tanstack/react-query';

import { api } from '~/auth';
import { API } from '~/config/apiConfig';
import type { Paginated } from '~/types/pagination';
import { QueryKey } from '~/types/react-query';

type MessageDeliveriesQuery = {
  skip: number;
  limit: number;
  messageDeliveryId?: string;
  authorName?: string;
  authorUpn?: string;
  message?: string;
  status?: 'scheduled' | 'sending' | 'sent' | 'failed';
  twilioMessagingServiceSid?: string;
};

const getMessageDeliveries = ({ queryParams }: { queryParams: MessageDeliveriesQuery }) => {
  const endpoint = `${API.PortalSms.url}/v1/message-deliveries`;

  return api
    .get(endpoint, {
      searchParams: queryParams,
    })
    .json<Paginated<MessageDeliveryDto>>();
};

export const useMessageDeliveries = ({ queryParams }: { queryParams: MessageDeliveriesQuery }) => {
  return useQuery({
    queryKey: [QueryKey.MessageDeliveries, queryParams],
    queryFn: () => getMessageDeliveries({ queryParams }),
  });
};
