import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Label } from '@intility/bifrost-react';

import { useSelectedRecipients } from '../../hooks/useSelectedRecipients';
import type { TFunctionProp } from './PreviewSummaryHeader';

export const PreviewSelectedRecipientsBox = ({ translate }: TFunctionProp) => {
  const { selectedRecipients } = useSelectedRecipients();
  const translatedPerson = translate('person');
  const translatedPeople = translate('people');
  const personOrPeople = selectedRecipients.length === 1 ? translatedPerson : translatedPeople;

  return (
    <div className='flex flex-col items-center gap-bfs-16 px-bfs-16 py-bfs-16 md:flex-row md:px-bfs-24'>
      <div className='flex h-[50px] w-[50px] flex-col  justify-center rounded-full bg-bfc-theme p-[10px]'>
        <Icon className='text-2xl' color='var(--bfc-theme-c-2)' icon={faUsers} />
      </div>
      <div>
        <div className={'flex items-center gap-1'}>
          <Label>{translate('Recipients')}</Label>
        </div>
        <p className='font-normal text-bfc-base-c-2'>
          {selectedRecipients.length} {personOrPeople}
        </p>
      </div>
    </div>
  );
};
