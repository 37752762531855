import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Button, FieldGroup, Icon, Input, useFloatingMessage } from '@intility/bifrost-react';
import { validatePhoneNumberLength } from 'libphonenumber-js';
import { AsYouType } from 'libphonenumber-js/mobile';
import { useState, type JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecipients, useSmsStoreActions } from '~/stores/smsStore';

export const CustomExternalRecipient = ({ searchValue }: { searchValue?: string }): JSX.Element => {
  const { showFloatingMessage } = useFloatingMessage();
  const { t } = useTranslation('create');
  const { addExternalPerson } = useSmsStoreActions();
  const recipients = useRecipients();

  const ERROR_LABEL = {
    TOO_SHORT: t('Number is too short'),
    TOO_LONG: t('Number is too long'),
    INVALID_COUNTRY: t('Invalid country code'),
    INVALID_LENGTH: t('Invalid length'),
    NOT_A_NUMBER: t('Not a number'),
    ALREADY_ADDED: t('This number has already been added'),
  } as const;

  const searchValueIsNumber = searchValue && !isNaN(Number(searchValue));
  const searchValueIsNotNumber = searchValue && isNaN(Number(searchValue));

  const [phoneNumberInput, setPhoneNumberInput] = useState(searchValueIsNumber ? searchValue : '');

  const [recipientNameInput, setRecipientNameInput] = useState(
    searchValueIsNotNumber ? searchValue : '',
  );

  const [errorFeedback, setErrorFeedback] = useState<string | null>(null);

  const clearAllInputs = () => {
    setErrorFeedback(null);
    setRecipientNameInput('');
    setPhoneNumberInput('');
  };

  const onSubmit = () => {
    const validatedPhoneNumber = validatePhoneNumberLength(phoneNumberInput);

    if (validatedPhoneNumber && ERROR_LABEL[validatedPhoneNumber]) {
      setErrorFeedback(ERROR_LABEL[validatedPhoneNumber]);
      return;
    }

    const recipient = phoneNumberInput ? recipients.get(phoneNumberInput) : undefined;
    const isAlreadyRecipient = recipient ? recipient.isSelected : false;

    if (isAlreadyRecipient) {
      return setErrorFeedback(ERROR_LABEL.ALREADY_ADDED);
    }

    addExternalPerson({
      id: String(phoneNumberInput),
      fullName: recipientNameInput || '',
      phoneNumber: String(phoneNumberInput),
      cityName: '',
      streetName: '',
    });

    clearAllInputs();

    showFloatingMessage(`${phoneNumberInput} ${t('was added')}`, {
      state: 'success',
    });
  };

  const onHandleInputChange = (input: string) => {
    setErrorFeedback(null);

    const asYouType = new AsYouType();
    setPhoneNumberInput(asYouType.input(input));
  };

  return (
    <div className='flex w-full flex-col md:gap-bfs-24 lg:flex-row'>
      <Input
        className={'mb-bfs-24 md:mb-0 md:min-w-[428px]'}
        label={t('Name')}
        placeholder={t('Name')}
        description={t('Name can help you navigate the recipient list')}
        clearable
        optional
        value={recipientNameInput}
        onChange={e => setRecipientNameInput(e.target.value)}
      />

      <FieldGroup
        label={t('Phone number')}
        description={t('All phone numbers must include the country code')}
        className={'col-span-1 flex md:min-w-[428px]'}
        state={!errorFeedback ? 'default' : 'alert'}
        feedback={errorFeedback ?? ''}
        required
      >
        <Input
          type={'tel'}
          placeholder={'+47 XX XX XX XX'}
          onChange={e => onHandleInputChange(e.target.value)}
          value={phoneNumberInput}
          label={'Phone number'}
          hideLabel
        />
      </FieldGroup>

      <div className='col-span-1 mb-[1.4rem]  self-end'>
        <Button
          onClick={onSubmit}
          state={!phoneNumberInput || errorFeedback ? 'inactive' : 'default'}
        >
          <Icon icon={faPlus} marginRight />
          {t('Add')}
        </Button>
      </div>
    </div>
  );
};
