import { faInfoCircle, faSend } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Label, Skeleton, Tooltip } from '@intility/bifrost-react';

import { useMessageSenders } from '../../api/getMessageSenders';
import type { TFunctionProp } from './PreviewSummaryHeader';

export const PreviewSenderBox = ({ translate }: TFunctionProp) => {
  const messageSender = useMessageSenders();
  const alphanumericSender = messageSender.data?.alphanumericSender;
  const phoneNumber = messageSender.data?.phoneNumber;
  const senderDisplayName = alphanumericSender ?? phoneNumber ?? translate('Unknown');

  return (
    <div className='flex flex-col items-center gap-bfs-16 px-bfs-16 py-bfs-16 md:flex-row md:px-bfs-24'>
      <div className='flex h-[50px] w-[50px] flex-col justify-center rounded-full bg-bfc-theme p-[10px]'>
        <Icon className='text-2xl' color='var(--bfc-theme-c-2)' icon={faSend} />
      </div>
      <div>
        <div className={'flex items-center gap-1'}>
          <Label>{translate('Sender')}</Label>
          <Tooltip
            content={translate(
              'Some countries do not support alphanumeric senders. In these countries a phone number will appear as the sender.',
            )}
          >
            <Icon icon={faInfoCircle} />
          </Tooltip>
        </div>
        <p className='font-normal text-bfc-base-c-2'>
          {messageSender.isPending ? <Skeleton.Text className={'w-[52px]'} /> : senderDisplayName}
        </p>
      </div>
    </div>
  );
};
