import queryString from 'query-string';
import { useQueryParams, type QueryParamConfigMapWithInherit } from 'use-query-params';

import { useDebounce } from './useDebounce';

export const useDebouncedQuery = <T extends QueryParamConfigMapWithInherit>(paramConfig: T) => {
  const [query, setQuery] = useQueryParams(paramConfig);

  const debouncedSetQuery = useDebounce((newQuery: Partial<typeof query>) => {
    setQuery(newQuery);
  }, 200);

  const parsedQueryParams = queryString.parse(queryString.stringify(query)) as Partial<
    typeof query
  >;

  return { query, debouncedSetQuery, parsedQueryParams };
};
