import { parsePhoneNumber } from 'libphonenumber-js/mobile';

export const cleanName = (name: string | undefined | null) => {
  return name?.split('/ Intility AS')[0]?.trim();
};

export const formatPhoneNumber = (phoneNumber: string) => {
  return parsePhoneNumber(phoneNumber).format('INTERNATIONAL');
};

export const formatCurrency = (value: number, options?: Intl.NumberFormatOptions) => {
  const currencyFormatter = new Intl.NumberFormat('nb-NO', {
    style: 'currency',
    currency: 'NOK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
    ...options,
  });

  return currencyFormatter.format(value);
};
