import { Button } from '@intility/bifrost-react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerContext } from '~/context/DrawerContext';
import { SelectedRecipientsDrawer } from './SelectedRecipientsDrawer';
import { cn } from '~/utils/clsx';
import { useSelectedRecipients } from '../../hooks/useSelectedRecipients';

export const RecipientViewButton = () => {
  const { handleDrawer } = useContext(DrawerContext);
  const { t } = useTranslation('create');
  const { selectedRecipients } = useSelectedRecipients();
  const hasRecipients = selectedRecipients.length;

  return (
    <Button
      state={hasRecipients ? 'default' : 'inactive'}
      className={cn('!rounded-xl  sm:w-fit')}
      onClick={() => {
        hasRecipients &&
          handleDrawer({
            content: <SelectedRecipientsDrawer />,
            header: t('Selected recipients'),
          });
      }}
    >
      {t('View selected recipients')}{' '}
      {Boolean(hasRecipients) && '(' + selectedRecipients.length + ')'}
    </Button>
  );
};
