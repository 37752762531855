import { useQuery } from '@tanstack/react-query';
import buildQuery from 'odata-query';

import { api } from '~/auth';
import { API } from '~/config/apiConfig';
import { QueryKey } from '~/types';
import type { Banner } from '~/types/publish';

type BannersDto = {
  '@odata.context': string;
  value: Array<Banner>;
};

const getPublishBanners = () => {
  const odataQuery = buildQuery({
    select: ['textContents'],
    expand: ['textContents'],
  });

  const endpoint = `${API.Publish.url}/api/v2/Banners?${odataQuery}`;

  return api.get(endpoint).json<BannersDto>();
};

export const usePublishBanners = () => {
  return useQuery({
    queryFn: () => getPublishBanners(),
    queryKey: [QueryKey.PublishBanners],
  });
};
