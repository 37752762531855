import { Button } from '@intility/bifrost-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from '~/routes/config';
import { useSmsStoreActions } from '~/stores/smsStore';

export const Welcome = () => {
  const { t } = useTranslation('create');

  const { setCurrentStep } = useSmsStoreActions();

  return (
    <div
      className={
        'm-auto flex flex-col items-center justify-center gap-bfs-24 p-bfs-16 sm:p-bfs-24 md:p-bfs-40'
      }
    >
      <h3 className={'text-center text-2xl font-h3'}>{`${t('Welcome to')} Intility SMS!`}</h3>

      <div className={'flex flex-col gap-bfs-16'}>
        <Button
          className={'m-auto !rounded-xl'}
          variant={'filled'}
          onClick={() => setCurrentStep('recipients')}
        >
          {t('Create SMS')}
        </Button>

        <div className={'flex gap-bfs-16'}>
          <Link to={routes.history.path}>
            <Button className={'w-[114px] !rounded-xl'}>{t('View history')}</Button>
          </Link>

          <Link to={routes.settings.path}>
            <Button className={'w-[114px] !rounded-xl'}>{t('Settings')}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
