import { Tabs } from '@intility/bifrost-react';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';

import {
  useSelectedExternalPeople,
  useSelectedGraphGroups,
  useSelectedGraphUsers,
} from '~/stores/smsStore';
import { ViewOnlyProvider } from '../../context/ViewOnlyContext';
import { SelectedExternalPeopleTable } from './SelectedExternalPeopleTable';
import { SelectedGraphGroupsTable } from './SelectedGraphGroupsTable';
import { SelectedGraphUsersTable } from './SelectedGraphUsersTable';
import { DrawerContext } from '~/context/DrawerContext';

interface SelectedRecipientsDrawerProps {
  viewOnly?: boolean;
}

export const SelectedRecipientsDrawer = ({ viewOnly = false }: SelectedRecipientsDrawerProps) => {
  const { t } = useTranslation('create');
  const { handleDrawer } = useContext(DrawerContext);
  const selectedGroups = useSelectedGraphGroups();
  const selectedGraphUsers = useSelectedGraphUsers();
  const selectedExternalPeople = useSelectedExternalPeople();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const allRecipientsGroups = [...selectedGroups, ...selectedGraphUsers, ...selectedExternalPeople];
  const tabs = [
    {
      title: t('Groups'),
      content: <SelectedGraphGroupsTable />,
      getContentLength: () => selectedGroups.length,
    },
    {
      title: t('People'),
      content: <SelectedGraphUsersTable />,
      getContentLength: () => selectedGraphUsers.length,
    },
    {
      title: t('External'),
      content: <SelectedExternalPeopleTable />,
      getContentLength: () => selectedExternalPeople.length,
    },
  ];
  useEffect(() => {
    if (!allRecipientsGroups.length) {
      handleDrawer({});
    }
  }, [allRecipientsGroups.length, handleDrawer]);

  useEffect(() => {
    //navigate to next populated tab if current tab is empty
    if (tabs[currentTab]?.getContentLength() === 0) {
      const nextTab = tabs.slice(currentTab + 1).findIndex(tab => tab.getContentLength() > 0);
      setCurrentTab(nextTab !== -1 ? nextTab + currentTab + 1 : 0);
    }
  }, [tabs, currentTab]);

  return (
    <ViewOnlyProvider viewOnly={viewOnly}>
      <Tabs variant='styled'>
        {tabs.map((tab, index) =>
          tab.getContentLength() > 0 ? (
            <Tabs.Item
              active={currentTab === index}
              onClick={() => setCurrentTab(index)}
              key={tab.title}
              noPadding
              content={<TabContentWrapper>{tab.content}</TabContentWrapper>}
            >
              {tab.title}
            </Tabs.Item>
          ) : null,
        )}
      </Tabs>
    </ViewOnlyProvider>
  );
};

const TabContentWrapper = ({ children }: { children: React.ReactNode }) => {
  return <div className='bg-bfc-base-2'>{children}</div>;
};
