import type { GraphUser } from '@intility/portal-sms-shared-utils';
import { createColumnHelper } from '@tanstack/react-table';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '~/components/table/Table';
import { useSelectedGraphUsers, useSmsStoreActions } from '~/stores/smsStore';
import { cleanName } from '~/utils/format';
import { ViewOnlyContext } from '../../context/ViewOnlyContext';
import { FailSafeButton } from './FailSafeButton';

const columnHelper = createColumnHelper<GraphUser>();

const columns = [
  columnHelper.accessor('displayName', {
    header: function Header() {
      const { t } = useTranslation('create');

      return t('Name');
    },
    cell: function Cell(cell) {
      return cleanName(cell.row.original.displayName);
    },
  }),
  columnHelper.accessor('mobilePhone', {
    id: 'members',
    header: function Header() {
      const { t } = useTranslation('create');

      return t('Phone number');
    },
  }),
  columnHelper.display({
    id: 'remove',
    cell: function Cell(cell) {
      const { viewOnly } = useContext(ViewOnlyContext);
      const { removeGraphUser } = useSmsStoreActions();
      const failSafeState = useState(false);

      if (viewOnly) return null;

      const person = cell.row.original;

      return (
        <FailSafeButton failSafeState={failSafeState} onConfirm={() => removeGraphUser(person)} />
      );
    },
    meta: {
      className: 'min-w-[100px]',
    },
  }),
];

export const SelectedGraphUsersTable = () => {
  const selectedGraphUsers = useSelectedGraphUsers();

  return (
    <Table
      data={selectedGraphUsers}
      columns={columns}
      meta={{
        noBorder: true,
      }}
    />
  );
};
