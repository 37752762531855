import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

// Credit: Intility Portal

const backend = resourcesToBackend((locale, namespace, callback) => {
  import(`../locales/${locale}/${namespace}.json`)
    .then(resources => {
      callback(null, resources.default);
    })
    .catch(error => {
      callback(error, null);
    });
});

await i18n
  .use(LanguageDetector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    supportedLngs: ['en-US', 'nb-NO'],
    fallbackLng: 'en-US',
    keySeparator: false, // we do not use keys in form object.welcome
    nsSeparator: false, // we do not use keys in form namespace:welcome
    ns: 'common',
    fallbackNS: 'common',
    // uncomment following line to enable debug log while in dev mode
    // debug: import.meta.env.DEV,
    interpolation: {
      escapeValue: false, // react already prevents xss
    },
  });

export default i18n;
