import { useState } from 'react';

import { useDebounce } from '~/utils/debounce';

export const useDebounceInput = () => {
  const [debouncedInput, setDebouncedInput] = useState<string>('');

  const setDebouncedValue = useDebounce((input: string) => {
    setDebouncedInput(input);
  }, 1000);

  return { debouncedInput, setDebouncedValue };
};
