import type { RouteObject } from 'react-router-dom';

import { Create } from '~/features/create';
import { History } from '~/features/history';
import { Settings } from '~/features/settings';

type Route = Record<string, RouteObject> | RouteObject;
type Routes = Record<string, Route>;

export const routes = {
  create: {
    path: '',
    element: <Create />,
    index: true,
    // loader: () => messageSenderLoader(queryClient),
  },
  history: {
    path: '/history',
    element: <History />,
  },
  settings: {
    path: '/settings',
    element: <Settings />,
  },
} satisfies Routes;
