import {
  BooleanParam as DefaultBooleanParam,
  StringParam as DefaultStringParam,
} from 'serialize-query-params';
import { createEnumParam, withDefault, type QueryParamConfig } from 'use-query-params';

export const BooleanParam = withDefault(DefaultBooleanParam, undefined) as QueryParamConfig<
  boolean | undefined
>;

export const StringParam = withDefault(DefaultStringParam, undefined) as QueryParamConfig<
  string | undefined
>;

export function EnumParam<TValues extends string>(values: TValues[]) {
  const enumParam = createEnumParam(values);

  return withDefault(enumParam, undefined) as QueryParamConfig<TValues | undefined>;
}
