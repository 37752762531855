import type { GraphUser } from '@intility/portal-sms-shared-utils';
import { useQuery } from '@tanstack/react-query';

import { api } from '~/auth/authorizedFetch';
import { API } from '~/config/apiConfig';
import { QueryKey } from '~/types';

type GraphGroupMembersDto = {
  '@odata.context': string;
  '@odata.nextLink': string;
  value: GraphUser[];
};

const getGraphGroupMembers = async (groupId: string) => {
  const endpoint = `${API.PortalSms.url}/v1/graph/groups/${groupId}/members`;
  const result = await api.get(endpoint).json<GraphGroupMembersDto>();

  return result.value;
};

export const useGraphGroupMembers = ({
  groupId,
  options,
}: {
  groupId: string;
  options?: { enabled?: boolean };
}) => {
  return useQuery({
    queryKey: [QueryKey.GraphGroupMembers, groupId],
    queryFn: () => getGraphGroupMembers(groupId),
    ...options,
  });
};
