import { Checkbox } from '@intility/bifrost-react';
import type { GraphUser } from '@intility/portal-sms-shared-utils';
import { createColumnHelper } from '@tanstack/react-table';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  addRecipients,
  removeRecipients,
  useRecipients,
  useSmsStoreActions,
  type GraphGroupWithMembers,
} from '~/stores/smsStore';
import { cleanName } from '~/utils/format';
import { ViewOnlyContext } from '../../context/ViewOnlyContext';
import { FailSafeButton } from './FailSafeButton';

const columnHelper = createColumnHelper<GraphGroupWithMembers>();

export const columns = [
  columnHelper.accessor('displayName', {
    header: function Header() {
      const { t } = useTranslation('create');

      return t('Group name');
    },
    meta: {
      className: 'max-w-[100px] break-words',
    },
  }),
  columnHelper.accessor(row => row.members, {
    id: 'members',
    header: function Header() {
      const { t } = useTranslation('create');

      return t('Members');
    },
    cell: function Cell({ row }) {
      const recipients = useRecipients();
      const members = row.original.members;

      const selectedRecipients = members.filter(member => {
        const phoneNumber = member.mobilePhone ?? '';
        const recipient = phoneNumber ? recipients.get(phoneNumber) : undefined;

        return recipient ? recipient.isSelected : false;
      });

      return `${selectedRecipients.length} / ${members.length}`;
    },
  }),
  columnHelper.display({
    id: 'remove',
    cell: function Cell({ cell }) {
      const { viewOnly } = useContext(ViewOnlyContext);
      const { removeGraphGroup } = useSmsStoreActions();
      const failSafeState = useState(false);

      if (viewOnly) return null;

      const group = cell.row.original;

      return (
        <FailSafeButton
          failSafeState={failSafeState}
          onConfirm={() => removeGraphGroup(group.id)}
        />
      );
    },
  }),
];

const groupMemberHelper = createColumnHelper<GraphUser>();

export const groupMemberColumns = [
  groupMemberHelper.accessor(row => cleanName(row.displayName), {
    id: 'displayName',
    header: function Header() {
      const { t } = useTranslation('create');

      return <span className='text-bfc-base-c'>{t('Member')}</span>;
    },
    meta: {
      className: '!py-bfs-8',
    },
  }),
  groupMemberHelper.accessor('mobilePhone', {
    header: function Header() {
      const { t } = useTranslation('create');

      return <span className='text-bfc-base-c'>{t('Phone number')}</span>;
    },
    meta: {
      className: '!py-bfs-8',
    },
  }),
  groupMemberHelper.display({
    id: 'toggle',
    meta: {
      className: '!py-bfs-8',
    },
    cell: function Cell({ row }) {
      const { viewOnly } = useContext(ViewOnlyContext);
      const recipients = useRecipients();

      if (viewOnly) return null;

      const groupMember = row.original;
      const phoneNumber = groupMember.mobilePhone ?? '';

      const recipient = phoneNumber ? recipients.get(phoneNumber) : undefined;
      const isChecked = recipient ? recipient.isSelected : false;

      const handleOnToggle = () => {
        if (!phoneNumber) return;

        if (isChecked) {
          removeRecipients([phoneNumber]);
        } else if (phoneNumber) {
          addRecipients([
            {
              name: groupMember.displayName,
              phoneNumber,
              isSelected: true,
              isExternal: false,
            },
          ]);
        }
      };

      return (
        <Checkbox label='' hideLabel type='switch' checked={isChecked} onChange={handleOnToggle} />
      );
    },
  }),
];
