import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { Drawer } from '@intility/bifrost-react';
import {
  createContext,
  useCallback,
  useMemo,
  useState,
  type Dispatch,
  type JSX,
  type SetStateAction,
} from 'react';

interface Props {
  children: React.ReactNode;
}

export type HandleDrawerType = {
  content?: JSX.Element;
  header?: string;
  icon?: IconDefinition;
};

interface DrawerContextProps {
  drawer?: boolean;
  handleDrawer: (props: HandleDrawerType) => void;
  drawerContent?: JSX.Element;
  drawerHeader?: string;
  drawerHeaderIcon?: IconDefinition;
  setDrawer?: Dispatch<SetStateAction<boolean>>;
}

const DrawerContext = createContext<DrawerContextProps>({} as DrawerContextProps);

const DrawerProvider = ({ children }: Props) => {
  const [drawer, setDrawer] = useState(false);
  const [drawerContent, setDrawerContent] = useState<JSX.Element>(<></>);
  const [drawerHeader, setDrawerHeader] = useState('Default header');
  const [drawerHeaderIcon, setDrawerHeaderIcon] = useState<IconDefinition>();

  const handleDrawer = useCallback(
    ({ content = <></>, header = '', icon }: HandleDrawerType) => {
      setDrawer(!drawer);
      setDrawerContent(content);
      setDrawerHeader(header);
      setDrawerHeaderIcon(icon);
    },
    [drawer],
  );

  const drawerValue: DrawerContextProps = useMemo(
    () => ({
      drawer,
      drawerContent,
      handleDrawer,
      drawerHeader,
      setDrawer,
      drawerHeaderIcon,
    }),
    [drawer, drawerContent, drawerHeader, setDrawer, drawerHeaderIcon, handleDrawer],
  );

  return (
    <DrawerContext.Provider value={drawerValue}>
      <Drawer
        onRequestClose={() => handleDrawer({})}
        isOpen={drawer}
        header={<div className={'mb-3 text-xl font-h4'}>{drawerHeader}</div>}
        overlay
      >
        {drawer && drawerContent}
      </Drawer>
      {children}
    </DrawerContext.Provider>
  );
};

DrawerContext.displayName = 'DrawerContext';

export { DrawerContext, DrawerProvider };
