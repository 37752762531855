import type { CustomerCreateMessageDeliveryDto } from '@intility/portal-sms-shared-utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { api } from '~/auth';
import { API } from '~/config/apiConfig';
import { QueryKey } from '~/types';

const createMessageDelivery = ({ inputDto }: { inputDto: CustomerCreateMessageDeliveryDto }) => {
  const endpoint = `${API.PortalSms.url}/v1/message-deliveries`;

  return api.post(endpoint, { json: inputDto }).json<{
    messageDeliveryId: string;
  }>();
};

export const useCreateMessageDelivery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ inputDto }: { inputDto: CustomerCreateMessageDeliveryDto }) =>
      createMessageDelivery({ inputDto }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.MessageDeliveries] });
    },
  });
};
