import { Button, Message, Spinner } from '@intility/bifrost-react';
import type { UseQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

interface Props {
  message?: string;
  queries?: UseQueryResult<unknown, unknown>[];
}
export const ErrorMessage = ({ message, queries }: Props) => {
  const { t } = useTranslation('common');
  const isFetching = queries?.some(query => query.isFetching);
  return (
    <Message className={'flex items-center justify-between rounded-xl'} state={'alert'}>
      {message ?? t('Unable to fetch data. Please try again')}
      <Button
        onClick={() => {
          queries?.forEach(query => {
            if (query.isError) {
              query.refetch();
            }
          });
        }}
        variant={'outline'}
        state={'alert'}
        small
        className={'!rounded-xl'}
      >
        {t('Retry')}
        {isFetching && <Spinner className={'ml-bfs-8 [&_svg]:!text-bfc-base-c-alert'} size={14} />}
      </Button>
    </Message>
  );
};
