import { Badge } from '@intility/bifrost-react';
import type { SmsRecipientDto } from '@intility/portal-sms-shared-utils';
import { TwilioMessageStatus } from '@intility/portal-sms-shared-utils';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { cleanName } from '~/utils/format';

const columnHelper = createColumnHelper<SmsRecipientDto>();

const TwilioStatusBadgeState = {
  [TwilioMessageStatus.ACCEPTED]: 'success' as const,
  [TwilioMessageStatus.SCHEDULED]: 'warning' as const,
  [TwilioMessageStatus.SENDING]: 'warning' as const,
  [TwilioMessageStatus.QUEUED]: 'warning' as const,
  [TwilioMessageStatus.UNDELIVERED]: 'warning' as const,
  [TwilioMessageStatus.CANCELED]: 'alert' as const,
  [TwilioMessageStatus.SENT]: 'success' as const,
  [TwilioMessageStatus.FAILED]: 'alert' as const,
  [TwilioMessageStatus.DELIVERED]: 'success' as const,
  [TwilioMessageStatus.RECEIVING]: 'neutral' as const,
  [TwilioMessageStatus.RECEIVED]: 'success' as const,
  [TwilioMessageStatus.READ]: 'success' as const,
} as const;

export const columns = [
  columnHelper.accessor('name', {
    header: function Header() {
      const { t } = useTranslation('common');

      return t('Name');
    },
    cell: function Cell(cell) {
      return cleanName(cell.row.original.name);
    },
  }),
  columnHelper.accessor('phoneNumber', {
    header: function Header() {
      const { t } = useTranslation('common');

      return t('Phone number');
    },
  }),
  columnHelper.accessor('messageStatus', {
    header: function Header() {
      const { t } = useTranslation('common');

      return t('Status');
    },
    cell: function Cell(cell) {
      const { t } = useTranslation('history');
      const messageStatus = cell.row.original.messageStatus;

      const friendlyStatuses: Record<TwilioMessageStatus, string> = {
        [TwilioMessageStatus.ACCEPTED]: t('Accepted'),
        [TwilioMessageStatus.SCHEDULED]: t('Queued'),
        [TwilioMessageStatus.SENDING]: t('Sending'),
        [TwilioMessageStatus.QUEUED]: t('Queued'),
        [TwilioMessageStatus.UNDELIVERED]: t('Undelivered'),
        [TwilioMessageStatus.CANCELED]: t('Canceled'),
        [TwilioMessageStatus.SENT]: t('Sent'),
        [TwilioMessageStatus.FAILED]: t('Failed'),
        [TwilioMessageStatus.DELIVERED]: t('Delivered'),
        [TwilioMessageStatus.RECEIVING]: t('Receiving'),
        [TwilioMessageStatus.RECEIVED]: t('Received'),
        [TwilioMessageStatus.READ]: t('Read'),
      } as const;

      return (
        <Badge state={TwilioStatusBadgeState[messageStatus]}>
          {friendlyStatuses[messageStatus]}
        </Badge>
      );
    },
  }),
];
