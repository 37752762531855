import { useTranslation } from 'react-i18next';

import { Errorbox } from '~/components/errors/Errorbox';
import { Table } from '~/components/table/Table';
import { useGraphUsers } from '../../api/getGraphUsers';
import { useDebounceInput } from '../../hooks/useDebounceInput';
import type { TableFilter } from './ActiveTableContainer';
import { FilteredSearchInput } from './FilteredSearchInput';
import { companyMemberColumns } from './GraphUserColumns';

interface Props {
  activeTableFilter: TableFilter;
  handleOnFilterChange: (filter: TableFilter) => void;
}

export const CompanyUserTable = ({ activeTableFilter, handleOnFilterChange }: Props) => {
  const { t } = useTranslation('create');

  const { debouncedInput, setDebouncedValue } = useDebounceInput();

  const graphUsers = useGraphUsers({
    queryParams: {
      displayName: debouncedInput,
    },
  });

  return (
    <div className={'flex flex-col gap-bfs-16'}>
      <FilteredSearchInput
        isLoading={graphUsers.isLoading}
        activeFilter={activeTableFilter}
        onFilterChange={filter => {
          handleOnFilterChange(filter);
        }}
        onChange={value => {
          setDebouncedValue(value);
        }}
      />

      {graphUsers.isError ? (
        <Errorbox queries={[graphUsers]} />
      ) : (
        <Table
          data={graphUsers.data}
          columns={companyMemberColumns}
          meta={{
            placeholder: graphUsers.isPending
              ? t('Search for individuals within your company')
              : undefined,
            isLoading: graphUsers.isLoading,
            noBorder: true,
            skeletonRows: 1,
          }}
          initialState={{
            pagination: {
              pageSize: 5,
            },
            sorting: [
              {
                id: 'displayName',
                desc: false,
              },
            ],
          }}
          className='rounded-xl'
        />
      )}
    </div>
  );
};
