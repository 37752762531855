import { ContentLayout } from '~/components/layout/ContentLayout';
import { useCurrentStep } from '~/stores/smsStore';
import { cn } from '~/utils/clsx';
import { Complete } from '../components/Complete';
import { Preview } from '../components/Preview';
import { SmsStepbar } from '../components/SmsStepbar';
import { Welcome } from '../components/Welcome';
import { WriteMessage } from '../components/WriteMessage';
import { RecipientSelectionTabContainer } from '../components/recipients/RecipientSelectionTabContainer';

const steps = {
  welcome: <Welcome />,
  recipients: <RecipientSelectionTabContainer />,
  write_message: <WriteMessage />,
  summary: <Preview />,
  complete: <Complete />,
};

export const Create = () => {
  const currentStep = useCurrentStep();

  return (
    <ContentLayout
      noHeader
      noPadding
      className='min-h-[calc(100svh_-_var(--bf-nav-top-height))] max-w-screen-xl pt-bfs-24 md:p-bfs-40'
    >
      <div
        className={
          'flex h-full w-full max-w-screen-xl flex-grow grid-cols-[auto_1fr] flex-col gap-bfs-40 md:grid md:gap-bfs-80 md:py-bfs-40 '
        }
      >
        <div className='w-full max-w-md self-center px-bfs-40 md:px-0'>
          <SmsStepbar />
        </div>

        <div className='flex grow md:items-center'>
          <div
            className={cn(
              'flex grow flex-col rounded-xl border-bfc-base-dimmed md:h-full',
              {
                'mx-bfs-16 border bg-bfc-base-2 p-bfs-16 max-md:mb-bfs-24 md:mx-0':
                  currentStep === 'welcome',
              }, // Always display border, background color and padding on welcome step
              { 'sm:border sm:bg-bfc-base-2': currentStep !== 'welcome' }, // The other steps should only display border and background color on small+ screens
            )}
          >
            {steps[currentStep]}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
