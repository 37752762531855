import { z } from 'zod';

const envSchema = z.object({
  environment: z.union([z.literal('development'), z.literal('production')]),
  version: z.string().optional(),
  portalAppId: z.string(),
  sentryDsn: z.string(),
  sentryEnvironment: z.string(),
  sentryRelease: z.string().optional(),
  portalApiAppId: z.string(),
  portalApiUrl: z.string(),
  publishApiUrl: z.string(),
  publishApiAppId: z.string(),
});

export type Env = z.infer<typeof envSchema>;

export const env: Env = {
  environment: import.meta.env.VITE_ENVIRONMENT,
  version: import.meta.env.VITE_VERSION,
  portalAppId: import.meta.env.VITE_PORTAL_APP_ID,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  sentryEnvironment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  sentryRelease: import.meta.env.VITE_SENTRY_RELEASE,
  portalApiAppId: import.meta.env.VITE_PORTAL_API_APP_ID,
  portalApiUrl: import.meta.env.VITE_PORTAL_API_URL,
  publishApiUrl: import.meta.env.VITE_PUBLISH_API_URL,
  publishApiAppId: import.meta.env.VITE_PUBLISH_API_APP_ID,
};

envSchema.parse(env);
