import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { Button, Icon } from '@intility/bifrost-react';
import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface FailSafeButtonProps {
  failSafeState: [boolean, Dispatch<SetStateAction<boolean>>];
  onConfirm: () => void;
}

export const FailSafeButton = ({ failSafeState, onConfirm }: FailSafeButtonProps) => {
  const { t } = useTranslation('create');

  const [failSafeView, setFailSafeView] = failSafeState;

  return failSafeView ? (
    <div className={'flex flex-col gap-bfs-4 text-center text-sm'}>
      <p className={'font-normal'}>{t('Remove')}?</p>

      <div className={'flex justify-center gap-bfs-8'}>
        <button onClick={() => setFailSafeView(false)}>{t('No')}</button>
        <div className={'border border-l'} />
        <button
          onClick={() => {
            onConfirm();
            setFailSafeView(false);
          }}
        >
          {t('Yes')}
        </button>
      </div>
    </div>
  ) : (
    <Button
      state={'alert'}
      variant={'outline'}
      small
      className='!rounded-lg'
      onClick={() => setFailSafeView(true)}
    >
      <Icon className={'text-bfc-base-c-alert'} icon={faTrashCan} />
    </Button>
  );
};
