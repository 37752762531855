import { Table } from '~/components/table/Table';
import { useSelectedExternalPeople } from '~/stores/smsStore';
import { columns } from './SelectedExternalPersonColumns';

export const SelectedExternalPeopleTable = () => {
  const externalPeople = useSelectedExternalPeople();

  return (
    <Table
      data={externalPeople}
      columns={columns}
      meta={{ noBorder: true, noExpandPadding: true }}
    />
  );
};
