import type { CompanyDto } from '@intility/portal-sms-shared-utils';
import { useQuery } from '@tanstack/react-query';

import { api } from '~/auth';
import { API } from '~/config/apiConfig';
import { QueryKey } from '~/types';

const getCompany = () => {
  const endpoint = `${API.PortalSms.url}/v1/companies`;

  return api.get(endpoint).json<CompanyDto>();
};

export const useCompany = () => {
  return useQuery({
    queryKey: [QueryKey.Company],
    queryFn: () => getCompany(),
  });
};
