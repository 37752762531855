import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { Bifrost, FloatingMessage, Spinner, enUS, nbNO } from '@intility/bifrost-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { instance } from '~/auth';
import { ApiAuthorizationProvider } from '~/context/AuthorizationContext';
import { DrawerProvider } from '~/context/DrawerContext';
import { ModalProvider } from '~/context/ModalContext';
import { ReactQueryProvider } from '~/lib/react-query';

const LocaleDeps = ({ children }: PropsWithChildren) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  return <Bifrost locale={locale === 'nb-NO' ? nbNO : enUS}>{children}</Bifrost>;
};

interface Props {
  children: React.ReactNode;
}

export const AppProvider = ({ children }: Props) => {
  return (
    <LocaleDeps>
      <Suspense fallback={<Spinner overlay />}>
        <MsalProvider instance={instance}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{ scopes: ['User.Read'] }}
          >
            <ApiAuthorizationProvider>
              <ReactQueryProvider>
                <FloatingMessage>
                  <DrawerProvider>
                    <ModalProvider>
                      {children}
                      <ReactQueryDevtools initialIsOpen={false} />
                    </ModalProvider>
                  </DrawerProvider>
                </FloatingMessage>
              </ReactQueryProvider>
            </ApiAuthorizationProvider>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </Suspense>
    </LocaleDeps>
  );
};
