import { useTranslation } from 'react-i18next';

import { ContentLayout } from '~/components/layout/ContentLayout';
import { SenderAccordion } from '../components/SenderAccordion';

export const Settings = () => {
  const { t } = useTranslation('settings');

  return (
    <ContentLayout
      noPadding
      pageTitle={t('Settings')}
      pageDescription={t('Make changes to your Intility SMS profile')}
      className='max-w-screen-xl p-bfs-16 md:px-bfs-80 md:py-bfs-40'
    >
      <SenderAccordion />
    </ContentLayout>
  );
};
