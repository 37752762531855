import { Modal } from '@intility/bifrost-react';
import '@intility/bifrost-react/dist/bifrost-app.css';
import '@total-typescript/ts-reset';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import '~/styles/global.scss';

import { AppProvider } from '~/providers/app';
import { router } from './routes/router';
import { initializeSentry } from './utils/initializeSentry';

const rootElement = document.getElementById('root')!;

// https://bifrost.intility.com/#/Components/Interactive/Modal
// Uncomment next line if using Modal from Bifrost
Modal.setAppElement(rootElement);
const root = createRoot(rootElement);

if (import.meta.env.DEV) {
  const { worker } = await import('~/test/msw/browser');

  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

root.render(
  <StrictMode>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </StrictMode>,
);

initializeSentry();
