import { BrowserTracing, init, reactRouterV6Instrumentation } from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { env } from './env';

/**
 * Initializes sentry if the application is built for production.
 * @see https://docs.sentry.io/platforms/javascript/guides/react
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
function initializeSentry() {
  if (import.meta.env.PROD) {
    init({
      dsn: env.sentryDsn,
      release: env.sentryRelease,
      environment: env.environment,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.5,
    });
  }
}

export { initializeSentry };
