import { QueryClient, QueryClientProvider, type DefaultOptions } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryConfig: DefaultOptions = {
  queries: {
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
  },
};

type ReactQueryProviderProps = {
  children: React.ReactNode;
};

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
});

export const ReactQueryProvider = ({ children }: ReactQueryProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <RQDevTools />
      {children}
    </QueryClientProvider>
  );
};

const RQDevTools = () => {
  if (import.meta.env.DEV) {
    return <ReactQueryDevtools buttonPosition='bottom-right' />;
  }

  return null;
};
