import { faInfoCircle, faMoneyBills } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Tooltip } from '@intility/bifrost-react';

import { useMessageBody } from '~/stores/smsStore';
import { formatCurrency } from '~/utils/format';
import { useSelectedRecipients } from '../../hooks/useSelectedRecipients';
import { calculateSegmentsCount } from '../../utils/calculateSegmentsCount';
import type { TFunctionProp } from './PreviewSummaryHeader';

export const PreviewEstimatedCostBox = ({ translate }: TFunctionProp) => {
  const messageBody = useMessageBody();
  const segmentsCount = calculateSegmentsCount(messageBody);
  const { selectedRecipients } = useSelectedRecipients();
  const recipientsCount = selectedRecipients.length;
  const costPerMessage = 0.51;
  const costEstimate = recipientsCount * segmentsCount * costPerMessage;

  return (
    <div className='flex flex-col items-center gap-bfs-16 px-bfs-16 py-bfs-16 md:flex-row md:px-bfs-24'>
      <div className='flex h-[50px] w-[50px] flex-col  justify-center rounded-full bg-bfc-theme p-[10px]'>
        <Icon className='text-2xl' color='var(--bfc-theme-c-2)' icon={faMoneyBills} />
      </div>
      <div>
        <div className={'flex items-center gap-1'}>
          <p className='font-semibold max-lg:hidden'>{translate('Cost estimate')}</p>
          <p className='font-semibold sm:hidden'>{translate('Cost')}</p>

          <Tooltip content={translate('Price per message * number of recipients')}>
            <Icon icon={faInfoCircle} />
          </Tooltip>
        </div>

        <p className={'font-normal text-bfc-base-c-2'}>
          {formatCurrency(costEstimate, { currencyDisplay: 'code' })}
        </p>
      </div>
    </div>
  );
};
