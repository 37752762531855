import { Button } from '@intility/bifrost-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from '~/routes/config';
import { useSmsStoreActions } from '~/stores/smsStore';

export const Complete = () => {
  const { t } = useTranslation('create');

  const { setCurrentStep } = useSmsStoreActions();

  return (
    <div className={'flex grow flex-col items-center justify-center'}>
      <h3 className={'text-2xl font-h3'}>{`${t('Completed')}!`}</h3>
      <div className={'mt-bfs-32 flex gap-bfs-16'}>
        <Button
          className={'m-auto !rounded-xl'}
          onClick={() => {
            setCurrentStep('recipients');
          }}
        >
          {t('Create new SMS')}
        </Button>

        <Link to={routes.history.path}>
          <Button className={'rounded-x w-[114px] !rounded-xl'}>{t('View history')}</Button>
        </Link>
      </div>
    </div>
  );
};
