import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Button, Checkbox, Input } from '@intility/bifrost-react';
import type { GraphUser } from '@intility/portal-sms-shared-utils';
import { createColumnHelper } from '@tanstack/react-table';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '~/components/table/Table';
import { DrawerContext } from '~/context/DrawerContext';
import {
  addRecipients,
  removeRecipients,
  useRecipients,
  type GraphGroupWithMembers,
} from '~/stores/smsStore';
import { cleanName, formatPhoneNumber } from '~/utils/format';

const columnHelper = createColumnHelper<GraphUser>();

const groupEditColumns = [
  columnHelper.accessor(row => cleanName(row.displayName), {
    id: 'name',
    meta: {
      className: 'max-w-[120px]',
    },
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Name');
    },
  }),
  columnHelper.accessor('mobilePhone', {
    enableSorting: false,
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Phone number');
    },
    cell: function Cell(cell) {
      return cell.row.original.mobilePhone
        ? formatPhoneNumber(cell.row.original.mobilePhone)
        : t('No phone number registered');
    },
  }),
  columnHelper.display({
    id: 'isSelected',
    meta: {
      className: 'w-[111px]',
    },
    enableSorting: false,
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Added');
    },
    cell: function Cell(cell) {
      const recipients = useRecipients();

      const rowData = cell.row.original;
      const phoneNumber = rowData.mobilePhone;
      const name = rowData.displayName;
      const recipient = phoneNumber ? recipients.get(phoneNumber) : undefined;
      const isChecked = recipient ? recipient.isSelected : false;

      const handleOnToggle = () => {
        if (!phoneNumber) return;

        if (isChecked) {
          removeRecipients([phoneNumber]);
        } else if (phoneNumber) {
          addRecipients([
            {
              name,
              phoneNumber,
              isSelected: true,
              isExternal: false,
            },
          ]);
        }
      };

      return (
        <Checkbox
          hideLabel
          label={''}
          checked={isChecked}
          onChange={handleOnToggle}
          type={'switch'}
        />
      );
    },
  }),
];

export const EditGroupRecipientDrawer = ({
  groupWithMembers,
}: {
  groupWithMembers: GraphGroupWithMembers;
}) => {
  const [searchInput, setSearchInput] = useState('');
  const { handleDrawer } = useContext(DrawerContext);
  const groupMembers = groupWithMembers.members;

  return (
    <>
      <Input
        onChange={e => setSearchInput(e.target.value)}
        className={'max-w-[50%]'}
        placeholder={t('Search for member')}
        icon={faSearch}
        hideLabel
        label={''}
      />

      <Table
        className={'mt-3'}
        data={groupMembers}
        columns={groupEditColumns}
        initialState={{
          pagination: {
            pageSize: 10,
          },
          sorting: [
            {
              id: 'name',
              desc: false,
            },
          ],
        }}
        state={{
          globalFilter: searchInput,
        }}
        meta={{
          noBorder: true,
        }}
      />

      <Button onClick={() => handleDrawer({})} className={'to-small mt-bfs-16 w-full !rounded-xl'}>
        {t('Close')}
      </Button>
    </>
  );
};
