import type { UpdateMessageSenderDto } from '@intility/portal-sms-shared-utils';
import { useMutation } from '@tanstack/react-query';

import { api } from '~/auth';
import { API } from '~/config/apiConfig';

const updateMessageSender = ({
  twilioMessagingServiceSid,
  inputDto,
}: {
  twilioMessagingServiceSid: string;
  inputDto: UpdateMessageSenderDto;
}) => {
  const endpoint = `${API.PortalSms.url}/v1/message-senders/${twilioMessagingServiceSid}`;

  return api.patch(endpoint, {
    json: inputDto,
  });
};

export const useUpdateMessageSender = () => {
  return useMutation({
    mutationFn: ({
      twilioMessagingServiceSid,
      inputDto,
    }: {
      twilioMessagingServiceSid: string;
      inputDto: UpdateMessageSenderDto;
    }) => updateMessageSender({ twilioMessagingServiceSid, inputDto }),
  });
};
