import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Grid, Input } from '@intility/bifrost-react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '~/components/table/Table';
import {
  useRecipients,
  useSelectedGraphGroups,
  type GraphGroupWithMembers,
} from '~/stores/smsStore';
import { ViewOnlyContext } from '../../context/ViewOnlyContext';
import { columns, groupMemberColumns } from './SelectedGraphGroupColumns';

export const SelectedGraphGroupsTable = () => {
  const selectedGraphGroups = useSelectedGraphGroups();

  return (
    <Table
      data={selectedGraphGroups}
      columns={columns}
      meta={{
        noBorder: true,
        noExpandPadding: false,
      }}
      ExpandedRowComponent={ExpandedGraphGroup}
    />
  );
};

const ExpandedGraphGroup = ({ rowData }: { rowData: GraphGroupWithMembers }) => {
  const { t } = useTranslation('create');
  const { viewOnly } = useContext(ViewOnlyContext);
  const recipients = useRecipients();

  const [searchInput, setSearchInput] = useState('');

  const selectedGroupMembers = rowData.members.filter(member => {
    if (!viewOnly) return true;

    const recipient = recipients.get(member.mobilePhone ?? '');

    return recipient ? recipient.isSelected : false;
  });

  return (
    <Grid className='gap-bfs-16'>
      <Input
        label=''
        placeholder={t('Search for group member')}
        hideLabel
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        icon={faSearch}
        variant='outline'
      />

      <Table
        data={selectedGroupMembers}
        columns={groupMemberColumns}
        state={{
          globalFilter: searchInput,
        }}
        initialState={{
          pagination: {
            pageSize: 10,
          },
          sorting: [
            {
              id: 'displayName',
              desc: false,
            },
          ],
        }}
        meta={{ noBorder: true, noExpandPadding: true }}
      />
    </Grid>
  );
};
