import { Message } from '@intility/bifrost-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePublishBanners } from '~/api/getPublishBanners';

export const PublishBanners = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const publishBanners = usePublishBanners();

  const [hiddenBannerIds, setHiddenBannerIds] = useState<string[]>([]);

  if (publishBanners.isPending || publishBanners.isError) {
    return;
  }

  const bannersToDisplay = publishBanners.data.value.filter(banner => {
    const isBannerHidden = hiddenBannerIds.includes(banner.id);

    return banner.textContents?.length && !isBannerHidden;
  });

  return (
    <>
      {bannersToDisplay.map(banner => {
        const desiredTextContentLanguage = locale === 'nb-NO' ? 'nb-no' : 'en-us';

        const textContentForSelectedLanguage = banner.textContents?.find(
          textContent => textContent.languageCode === desiredTextContentLanguage,
        );

        const bannerContent = textContentForSelectedLanguage?.htmlBody;

        if (!bannerContent) {
          return null;
        }

        return (
          <Message
            key={banner.id}
            statusBar
            state={banner.severity === 'theme' ? 'default' : banner.severity}
            onClose={() => setHiddenBannerIds([...hiddenBannerIds, banner.id])}
          >
            <span dangerouslySetInnerHTML={{ __html: bannerContent }}></span>
          </Message>
        );
      })}
    </>
  );
};
