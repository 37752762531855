import { Grid } from '@intility/bifrost-react';
import type { MessageDeliveryDto } from '@intility/portal-sms-shared-utils';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerContext } from '~/context/DrawerContext';
import { cn } from '~/utils/clsx';
import { SmsRecipentsDrawer } from './SmsRecipientsDrawer';

export const ExpandedMessageDelivery = ({ rowData }: { rowData: MessageDeliveryDto }) => {
  const { t } = useTranslation('history');
  const { handleDrawer } = useContext(DrawerContext);

  const recipients = rowData.recipients;
  const recipientsLength = recipients.length;

  const translatedPerson = t('person');
  const translatedPeople = t('people');
  const personOrPeople = recipientsLength === 1 ? translatedPerson : translatedPeople;

  return (
    <Grid className='!grid-cols-[auto_1fr] bg-bfc-base-2 px-bfs-24 py-bfs-16'>
      {[
        {
          header: t('Message'),
          value: <p className='text-sm'>{rowData.message}</p>,
        },
        {
          header: t('Recipients'),
          value: (
            <button
              className='bf-link'
              onClick={() =>
                handleDrawer({
                  header: t('Recipients'),
                  content: <SmsRecipentsDrawer recipients={recipients} />,
                })
              }
            >
              {recipientsLength} {personOrPeople}
            </button>
          ),
          className: 'to-medium',
        },
        {
          header: t('Sender'),
          value: rowData.companyName,
          className: 'to-medium',
        },
        {
          header: t('Author'),
          value: rowData.authorName,
          className: 'to-medium',
        },

        {
          header: t('Status'),
          value: rowData.status,
          className: 'to-small',
        },
      ].map(({ header, value, className }) => (
        <>
          <p className={cn('font-semibold', className)}>{header}</p>
          <p className={cn(className)}>{value}</p>
        </>
      ))}
    </Grid>
  );
};
