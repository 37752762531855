import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { Button, Checkbox, Grid, useFloatingMessage } from '@intility/bifrost-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MessageBubble } from '~/components/MessageBubble';
import { ErrorMessage } from '~/components/errors/ErrorMessage';
import { useMessageBody, useSmsStoreActions } from '~/stores/smsStore';
import { useCreateMessageDelivery } from '../api/createMessageDelivery';
import { useMessageSenders } from '../api/getMessageSenders';
import { useSelectedRecipients } from '../hooks/useSelectedRecipients';
import { PreviewSummaryHeader } from './recipients/PreviewSummaryHeader';
import { RecipientViewButton } from './recipients/RecipientViewButton';

export const Preview = () => {
  const { t } = useTranslation('create');
  const createMessageDelivery = useCreateMessageDelivery();
  const { setCurrentStep, reset } = useSmsStoreActions();
  const { showFloatingMessage } = useFloatingMessage();
  const { selectedRecipients } = useSelectedRecipients();
  const messageSender = useMessageSenders();
  const messageBody = useMessageBody();

  const [hasConfirmedMessage, setHasConfirmedMessage] = useState(false);
  const isButtonInactive =
    !messageSender.data || messageSender.isError || createMessageDelivery.isPending;

  const handleUnconfirmedMessage = () => {
    showFloatingMessage(t('You must accept the message and cost estimate before sending the SMS'), {
      state: 'warning',
    });
  };

  const handleSendSms = () => {
    if (!messageSender.data) return;

    createMessageDelivery.mutate(
      {
        inputDto: {
          message: messageBody,
          recipients: selectedRecipients.map(r => ({
            name: r.name,
            phoneNumber: r.phoneNumber,
          })),
          twilioMessagingServiceSid: messageSender.data.twilioMessagingServiceSid,
        },
      },
      {
        onSuccess: () => {
          setCurrentStep('complete');
          reset();
        },
        onError: () => {
          showFloatingMessage(
            t('Unable to send SMS. Please try again.', {
              state: 'alert',
            }),
          );
        },
      },
    );
  };

  return (
    <div className='flex h-full flex-col rounded-xl bg-bfc-base'>
      <div className='rounded-xl bg-bfc-base-3'>
        <div className='p-bfs-24 md:p-bfs-40'>
          <h4 className={'font-h4 md:text-xl'}>{t('Preview')}</h4>
          <p className={'bf-label-description'}>
            {t('Review recipients, cost estimate, and message text before sending your message.')}
          </p>
        </div>
        <PreviewSummaryHeader />
      </div>

      <div className='flex h-full flex-col p-bfs-24 md:p-bfs-40 md:pb-bfs-32'>
        <div className={'mt-auto flex justify-end md:pb-bfs-24'}>
          <MessageBubble messageBody={messageBody} />
        </div>
        {messageSender.isError && (
          <ErrorMessage
            message={t("Could not get the sender's information.")}
            queries={[messageSender]}
          />
        )}
      </div>

      <Grid
        className='mt-auto rounded-b-xl border-t-2 border-t-bfc-base-dimmed bg-bfc-base-3 px-bfs-24 py-bfs-40 md:px-bfs-40'
        small={2}
      >
        <RecipientViewButton />
        <div className='grid gap-bfs-16 sm:grid-cols-[1fr_auto]'>
          <Checkbox
            className='m-auto sm:ml-auto'
            label={t('I accept the message and cost estimate')}
            checked={hasConfirmedMessage}
            onChange={() => setHasConfirmedMessage(state => !state)}
          />
          <Grid cols={2}>
            <Button
              onClick={() => setCurrentStep('write_message')}
              className={'my-auto !rounded-xl'}
            >
              {t('Go back')}
            </Button>
            <Button
              icon={faPaperPlane}
              className={'my-auto !rounded-xl'}
              variant={'filled'}
              state={isButtonInactive || !hasConfirmedMessage ? 'inactive' : 'default'}
              onClick={!hasConfirmedMessage ? handleUnconfirmedMessage : handleSendSms}
            >
              Send
            </Button>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};
