import type { ExpandedMessageSenderDto } from '@intility/portal-sms-shared-utils';
import { type QueryClient, useQuery } from '@tanstack/react-query';

import { api } from '~/auth/authorizedFetch';
import { API } from '~/config/apiConfig';
import { QueryKey } from '~/types';

export const messageSenderQuery = {
  queryKey: [QueryKey.MessageSenders],
  queryFn: async () => getMessageSenders(),
};

export const messageSenderLoader = async (queryClient: QueryClient) => {
  const query = messageSenderQuery;

  return queryClient.getQueryData([query.queryKey]) ?? (await queryClient.fetchQuery(query));
};

export const getMessageSenders = async () => {
  const endpoint = `${API.PortalSms.url}/v1/message-senders`;
  const result = await api.get(endpoint).json<ExpandedMessageSenderDto[]>();

  return result.length ? result[0] : null;
};

export const useMessageSenders = () => {
  return useQuery(messageSenderQuery);
};
