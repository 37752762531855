import { Badge, useBreakpoint } from '@intility/bifrost-react';
import type { PaginationState } from '@tanstack/react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Errorbox } from '~/components/errors/Errorbox';
import { Table } from '~/components/table/Table';
import type { ParsedExternalRecipient } from '~/types';
import { useExternalRecipients } from '../../api/getExternalRecipients';
import { useDebounceInput } from '../../hooks/useDebounceInput';
import type { TableFilter } from './ActiveTableContainer';
import { externalRecipientColumns } from './ExternalRecipientColumns';
import { FilteredSearchInput } from './FilteredSearchInput';

interface Props {
  activeTableFilter: TableFilter;
  handleOnFilterChange: (filter: TableFilter) => void;
}
export const ExternalRecipientTable = ({ activeTableFilter, handleOnFilterChange }: Props) => {
  const { t } = useTranslation('create');
  const toMedium = useBreakpoint(null, 'medium');
  const { debouncedInput, setDebouncedValue } = useDebounceInput();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });

  const externalRecipients = useExternalRecipients({
    queryParams: {
      query: debouncedInput,
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
    },
  });

  return (
    <div className={'flex flex-col gap-bfs-16'}>
      <FilteredSearchInput
        isLoading={externalRecipients.isLoading}
        activeFilter={activeTableFilter}
        onFilterChange={filter => {
          handleOnFilterChange(filter);
        }}
        onChange={value => {
          setDebouncedValue(value);
        }}
      />

      {externalRecipients.isError ? (
        <Errorbox queries={[externalRecipients]} />
      ) : (
        <Table
          data={externalRecipients.data?.items}
          columns={externalRecipientColumns}
          ExpandedRowComponent={toMedium ? ExpandedAddress : undefined}
          onPaginationChange={setPagination}
          manualPagination
          pageCount={Math.ceil(Number(externalRecipients.data?.totalItems) / pagination.pageSize)}
          meta={{
            customNoResult: t('No results found, add recipient manually'),
            placeholder: externalRecipients.isPending
              ? t('Search the Norwegian telephone directory')
              : undefined,
            isLoading: externalRecipients.isLoading,
            skeletonRows: 1,
            noBorder: true,
          }}
          initialState={{
            sorting: [
              {
                id: 'fullName',
                desc: false,
              },
            ],
          }}
          state={{
            pagination: pagination,
          }}
          className='rounded-xl'
        />
      )}
    </div>
  );
};

const ExpandedAddress = ({ rowData }: { rowData: ParsedExternalRecipient }) => {
  const { t } = useTranslation('create');
  return (
    <>
      {rowData.streetName && rowData.cityName ? (
        <div className={'ml-[2.2rem] flex gap-bfs-12'}>
          <p className={'bf-strong'}>{t('Address')}:</p>
          <p>
            {rowData.streetName}, {rowData.cityName}
          </p>
        </div>
      ) : (
        <Badge className={'ml-[2.2rem]'} state={'warning'}>
          {t('No registered address')}
        </Badge>
      )}
    </>
  );
};
