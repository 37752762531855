import type { AuthenticationResult } from '@azure/msal-browser';
import type { AzureToken } from '@intility/mop-utils';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';

import { instance } from '~/auth';

export const useAuthorization = (scope: string[], acceptedRoles: string[]) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
  const [authResult, setAuthResult] = useState<AuthenticationResult | null>(null);

  useEffect(() => {
    const activeAccount = instance.getActiveAccount();
    if (activeAccount) {
      instance
        .acquireTokenSilent({
          scopes: scope,
          account: activeAccount,
        })
        .then(authResultToken => {
          if (!Array(authResultToken)[0]) {
            console.warn('No authentication result found.');
            setIsAuthorized(null);
          } else {
            setAuthResult(authResultToken);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (authResult) {
      const accessToken = authResult.accessToken;
      const checkRoles = (tokenRoles: string[]) =>
        tokenRoles.some((role: string) => acceptedRoles.includes(role));
      const tokenRoles = jwtDecode<AzureToken>(accessToken).roles;
      setIsAuthorized(tokenRoles ? checkRoles(tokenRoles) : false);
    }
  }, [authResult]);

  return isAuthorized;
};
