import { matchSorter } from 'match-sorter';
import { useTranslation } from 'react-i18next';

import { Errorbox } from '~/components/errors/Errorbox';
import { Table } from '~/components/table/Table';
import { useGraphGroups } from '../../api/getGraphGroups';
import { useDebounceInput } from '../../hooks/useDebounceInput';
import type { TableFilter } from './ActiveTableContainer';
import { FilteredSearchInput } from './FilteredSearchInput';
import { groupColumns } from './GraphGroupColumns';

interface Props {
  activeTableFilter: TableFilter;
  handleOnFilterChange: (filter: TableFilter) => void;
}

export const CompanyGroupTable = ({ handleOnFilterChange, activeTableFilter }: Props) => {
  const { t } = useTranslation('create');

  const { debouncedInput, setDebouncedValue } = useDebounceInput();

  const graphGroups = useGraphGroups({
    queryParams: {
      displayName: debouncedInput,
    },
  });

  const sortedGroups = matchSorter(graphGroups.data ?? [], debouncedInput, {
    threshold: matchSorter.rankings.NO_MATCH,
    keys: ['displayName'],
  });

  return (
    <div className={'flex flex-col gap-bfs-16'}>
      <FilteredSearchInput
        isLoading={graphGroups.isLoading}
        activeFilter={activeTableFilter}
        onFilterChange={filter => {
          handleOnFilterChange(filter);
        }}
        onChange={value => {
          setDebouncedValue(value);
        }}
      />

      {graphGroups.isError ? (
        <Errorbox queries={[graphGroups]} />
      ) : (
        <Table
          data={sortedGroups}
          columns={groupColumns}
          meta={{
            placeholder: graphGroups.isPending
              ? t('Search for groups within your company')
              : undefined,
            isLoading: graphGroups.isLoading,
            noBorder: true,
            skeletonRows: 1,
          }}
          initialState={{
            pagination: {
              pageSize: 5,
            },
          }}
          enableSorting
          className='rounded-xl'
        />
      )}
    </div>
  );
};
