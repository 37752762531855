import { faCopy, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { Button, Grid, Icon, Input, Tooltip } from '@intility/bifrost-react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalContext } from '~/context/ModalContext';
import { useSmsStoreActions } from '~/stores/smsStore';

export const ClearTextPasswordWarning = ({
  potentialPasswords,
}: {
  potentialPasswords: string[];
}) => {
  const { t } = useTranslation('create');
  const { setCurrentStep } = useSmsStoreActions();
  const { handleModal } = useContext(ModalContext);

  return (
    <div className='flex flex-col md:max-w-[500px]'>
      <div className='mb-bfs-16 flex gap-bfs-8'>
        <Icon size='2x' color='var(--bfc-alert)' icon={faTriangleExclamation} />
        <h3 className='text-2xl font-h3'>{t('Warning!')}</h3>
      </div>
      <Grid gap={24}>
        <div className='flex flex-col gap-bfs-12'>
          <p>
            {t(
              'It looks like you are trying to send a password in plain text. This is a security issue, and we strongly encourage the use of a secure password sharing service',
            )}
          </p>
          <p>{t('Potential password found')}:</p>
          <Grid gap={8}>
            {potentialPasswords.map(password => (
              <PotentialPassWordInput key={password} password={password} />
            ))}
          </Grid>
        </div>
        <div className='ml-auto flex gap-bfs-12'>
          <Button onClick={() => handleModal({})} variant='outline' className='!rounded-xl'>
            {t('Back to message')}
          </Button>
          <Button
            onClick={() => {
              handleModal({});
              setCurrentStep('summary');
            }}
            variant='filled'
            className='!rounded-xl'
          >
            {t('Proceed anyway')}
          </Button>
        </div>
      </Grid>
    </div>
  );
};

const PotentialPassWordInput = ({ password }: { password: string }) => {
  const { t } = useTranslation('create');
  const [visible, setVisible] = useState(false);

  const handleIconClick = () => {
    navigator.clipboard
      .writeText(password)
      .then(() => {
        setVisible(prev => !prev);
        setTimeout(() => setVisible(prev => !prev), 500);
      })
      .catch(error => {
        // Log an error if the copy operation fails
        console.error('Failed to copy input value to clipboard', error);
      });
  };
  return (
    <Tooltip
      className='left-[11rem] sm:left-[20rem] md:left-[15rem]'
      visible={visible}
      variant='compact'
      content={t('copied')}
      placement='top'
    >
      <Input
        inputClassName='!border-0 focus:shadow-none'
        color='var(--bfc-base-c-1)'
        icon={faCopy}
        value={password}
        onIconClick={() => handleIconClick()}
        rightIcon
        hideLabel
        label=''
        placeholder=''
      />
    </Tooltip>
  );
};
