import { EventType, PublicClientApplication } from '@azure/msal-browser';

import { config } from './config';

/**
 * A PublicClientApplication instance
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const instance = new PublicClientApplication(config);

await instance.initialize();

instance.addEventCallback(message => {
  if (
    message.eventType === EventType.LOGIN_SUCCESS ||
    message.eventType === EventType.SSO_SILENT_SUCCESS
  ) {
    if (message.payload && 'account' in message.payload) {
      const account = message.payload.account;

      if (account) {
        instance.setActiveAccount(account);
      }
    }
  }
});

if (!instance.getActiveAccount()) {
  const accounts = instance.getAllAccounts();

  if (accounts.length) {
    const account = accounts[0];

    if (account) {
      instance.setActiveAccount(account);
    }
  }
}

/**
 * Register event callback to set the active account after successful login
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/accounts.md#active-account-apis
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md
 */

export { instance };
