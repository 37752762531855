import { faRedo } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Message } from '@intility/bifrost-react';
import { type UseQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

interface ErrorboxProps {
  queries?: UseQueryResult<unknown, unknown>[];
}

export const Errorbox = ({ queries }: ErrorboxProps) => {
  const { t } = useTranslation();

  const isFetching = queries?.some(query => query.isFetching);

  return (
    <Message className={'items-center gap-bfs-4 sm:flex'} state='alert'>
      {t('Unable to fetch data. Please try again.')}

      {queries && (
        <>
          <Icon icon={faRedo} fixedWidth spin={isFetching} className='ml-bfs-4' />

          {!isFetching && (
            <button
              className='bf-link'
              onClick={() => {
                queries.forEach(query => {
                  if (query.isError) {
                    query.refetch();
                  }
                });
              }}
            >
              {t('Try again')}
            </button>
          )}
        </>
      )}
    </Message>
  );
};
