import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Button, Input } from '@intility/bifrost-react';
import { useTranslation } from 'react-i18next';

import { cn } from '~/utils/clsx';
import { type TableFilter } from './ActiveTableContainer';

interface Props {
  className?: string;
  activeFilter: TableFilter;
  onFilterChange: (filter: TableFilter) => void;
  onChange: (searchInput: string) => void;
  isLoading: boolean;
}
type FilterCategory = { label: string; value: TableFilter }[];
export const FilteredSearchInput = ({
  className,
  onFilterChange,
  activeFilter,
  onChange,
  isLoading,
}: Props) => {
  const { t } = useTranslation('create');
  const searchFilterCategory: FilterCategory = [
    { label: t('Group'), value: 'GROUP' },
    { label: t('Person'), value: 'INTERNAL' },
    { label: t('White pages'), value: 'EXTERNAL' },
  ];

  return (
    <div className={'flex flex-col gap-bfs-8 sm:flex-row'}>
      <Button.Group className={'mr-auto rounded-md md:m-0'}>
        {searchFilterCategory.map(({ label, value }) => (
          <Button
            className={'!rounded-md'}
            onClick={() => onFilterChange(value)}
            key={value}
            active={activeFilter === value}
          >
            {label}
          </Button>
        ))}
      </Button.Group>
      <Input
        className={cn('!m-0 grow [&_input]:rounded-md', className)}
        onChange={e => onChange(e.target.value)}
        // onIconClick={simulateHttpRequest}
        icon={faSearch}
        iconButton
        rightIcon
        hideLabel
        label={''}
        placeholder={t('Search...')}
        clearable
        loading={isLoading}
        variant='outline'
      />
    </div>
  );
};
