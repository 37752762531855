import { Badge, Checkbox } from '@intility/bifrost-react';
import { createColumnHelper } from '@tanstack/react-table';
import { parsePhoneNumber } from 'libphonenumber-js/mobile';
import { useTranslation } from 'react-i18next';

import { useRecipients, useSmsStoreActions } from '~/stores/smsStore';
import type { ParsedExternalRecipient } from '~/types';

const columnHelper = createColumnHelper<ParsedExternalRecipient>();
export const externalRecipientColumns = [
  columnHelper.accessor('fullName', {
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Name');
    },
  }),
  columnHelper.accessor('phoneNumber', {
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Phone number');
    },
    cell: function Cell(cell) {
      const phoneNumber = parsePhoneNumber(cell.row.original.phoneNumber);
      return phoneNumber.format('INTERNATIONAL');
    },
  }),
  columnHelper.accessor('streetName', {
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Address');
    },
    cell: function Cell(cell) {
      const { t } = useTranslation('create');
      const streetName = cell.row.original.streetName;
      const cityName = cell.row.original.cityName;

      return (
        <>
          {streetName && cityName ? (
            `${streetName}, ${cityName}`
          ) : (
            <Badge state={'warning'}>{t('No registered address')}</Badge>
          )}
        </>
      );
    },
    meta: {
      fromSize: 'medium',
    },
  }),
  columnHelper.display({
    id: 'isSelected',
    enableSorting: false,
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Added');
    },
    meta: {
      className: '!py-0',
    },
    cell: function Cell(cell) {
      const { addExternalPerson, removeExternalPerson } = useSmsStoreActions();

      const person = cell.row.original;
      const phoneNumber = person.phoneNumber;

      const recipients = useRecipients();
      const recipient = phoneNumber ? recipients.get(phoneNumber) : undefined;
      const isChecked = recipient ? recipient.isSelected : false;

      const handleOnToggle = () => {
        if (!phoneNumber) return;

        if (isChecked) {
          removeExternalPerson(person);
        } else {
          addExternalPerson(person);
        }
      };

      return (
        <Checkbox
          type={'switch'}
          onChange={handleOnToggle}
          hideLabel
          label={''}
          checked={isChecked}
        />
      );
    },
  }),
];
