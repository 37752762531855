import { Button, Grid, TextArea, useFloatingMessage } from '@intility/bifrost-react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { MessageBubble } from '~/components/MessageBubble';
import { ModalContext } from '~/context/ModalContext';
import { useDebounce } from '~/hooks/useDebounce';
import { useMessageBody, useSmsStoreActions } from '~/stores/smsStore';
import { useSelectedRecipients } from '../hooks/useSelectedRecipients';
import { ClearTextPasswordWarning } from './recipients/ClearTextPasswordWarning';
import { RecipientViewButton } from './recipients/RecipientViewButton';

export const WriteMessage = () => {
  const { handleModal } = useContext(ModalContext);
  const { t } = useTranslation('create');
  const { selectedRecipients } = useSelectedRecipients();
  const { showFloatingMessage } = useFloatingMessage();
  const { setMessageBody, setCurrentStep } = useSmsStoreActions();
  const hasRecipients = selectedRecipients.length;
  const savedMessageBody = useMessageBody();
  const [emptyFeedback, setEmptyFeedback] = useState(false);

  const emptyMessageError = t('Your message cannot be empty');
  const noRecipientsError = t('You must select at least one recipient');

  const messageValidation = z
    .string()
    .refine(() => hasRecipients, {
      message: t(noRecipientsError),
    })
    .safeParse(savedMessageBody);

  const setDebouncedMessage = useDebounce((message: string) => {
    setMessageBody(message);
  }, 200);

  const handleValidation = () => {
    if (!savedMessageBody) {
      setEmptyFeedback(true);
      showFloatingMessage(emptyMessageError, {
        state: 'warning',
      });
      return;
    }
    if (!hasRecipients) {
      showFloatingMessage(noRecipientsError, {
        state: 'warning',
      });
      return;
    }
    handlePlainTextPassword();
  };

  const handlePlainTextPassword = () => {
    const urlRegex = /((https?|ftp):\/\/[^\s/$.?#].[^\s]*)/gi; // Regex to detect URL links
    const passwordRegex =
      /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9\s]?)[\s\S]{8,}|(.*?(\w+[-.,_\\\d]+){2,}\w+)/; // Regex to detect plaintext password
    const bitlockerKeyRegex = /\b(\d{6}-){7}\d{6}\b/; // Regex to detect Bitlocker key

    // Remove URL links from the message and split the message into words
    const messageWithoutUrls = savedMessageBody.replace(urlRegex, '');

    const splittedMessage = messageWithoutUrls.split(' ');

    // Find potential passwords and Bitlocker keys
    const potentialPasswords: string[] = [];
    for (const word of splittedMessage) {
      word.match(passwordRegex) && potentialPasswords.push(word);
      word.match(bitlockerKeyRegex) && potentialPasswords.push(word);
    }

    if (potentialPasswords.length > 0) {
      handleModal({
        content: <ClearTextPasswordWarning potentialPasswords={potentialPasswords} />,
      });
    } else {
      setCurrentStep('summary');
    }
  };

  return (
    <div className='flex h-full flex-col rounded-xl bg-bfc-base'>
      <div className='rounded-t-xl border-b border-b-bfc-base-dimmed bg-bfc-base-3'>
        <div className='p-bfs-24 md:p-bfs-40'>
          <h4 className={'font-h4 md:text-xl'}>{t('Send SMS')}</h4>
          <p className={'bf-label-description break-word'}>
            {t('Write and preview the message you wish to send.')} <br className='sm:hidden' />
            {t(
              'For security reasons, it is discouraged to send passwords and other sensitive information via Intility SMS.',
            )}
          </p>
        </div>
      </div>

      <div className='mt-auto flex flex-col p-bfs-24 md:p-bfs-40 md:pb-bfs-32'>
        <div className={'flex flex-col justify-end md:pb-bfs-24'}>
          <div className={'bf-scrollbar ml-auto overflow-y-auto'}>
            <h4 className={'mb-bfs-4 ml-bfs-16 text-xs text-bfc-base-c-wcag'}>{t('Preview')}</h4>
            <MessageBubble messageBody={savedMessageBody || '...'} />
          </div>
        </div>
        <TextArea
          className={'mt-bfs-16'}
          label={t('Message')}
          placeholder={t('Message content')}
          textAreaClassName={'mt-bfs-8 !max-h-[76px]'}
          defaultValue={savedMessageBody}
          feedback={
            <>
              {emptyFeedback && <p>{t(emptyMessageError)}</p>}
              {!messageValidation.success && <p>{messageValidation.error.errors[0]?.message}</p>}
            </>
          }
          state={!messageValidation.success || emptyFeedback ? 'alert' : 'default'}
          onChange={e => {
            setDebouncedMessage(e.target.value);
            setEmptyFeedback(false);
          }}
        />
      </div>

      <Grid
        className='rounded-b-xl border-t-2 border-t-bfc-base-dimmed bg-bfc-base-3 px-bfs-24 py-bfs-40 md:px-bfs-40'
        small={2}
      >
        <RecipientViewButton />
        <Grid cols={2} className='sm:place-self-end'>
          <Button className={'!rounded-xl'} pill onClick={() => setCurrentStep('recipients')}>
            {t('Previous')}
          </Button>
          <Button
            variant={'filled'}
            onClick={handleValidation}
            className={'!rounded-xl'}
            pill
            state={savedMessageBody && hasRecipients ? 'default' : 'inactive'}
          >
            {t('Next')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
