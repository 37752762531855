import { instance } from '~/auth';
import { Errorbox } from '~/components/errors/Errorbox';
import { Table } from '~/components/table/Table';
import { useDebouncedQuery } from '~/hooks/useDebouncedQuery';
import { useMessageDeliveries } from '../api/getMessageDeliveries';
import { ExpandedMessageDelivery } from './ExpandedMessageDelivery';
import { columns } from './HistoryColumns';
import { HistoryParamConfig } from './HistoryOptions';

export const HistoryTable = () => {
  const { query } = useDebouncedQuery(HistoryParamConfig);

  const loggedInUser = instance.getActiveAccount();

  const messageDeliveries = useMessageDeliveries({
    queryParams: {
      limit: 250,
      skip: 0,
      ...(query.tab === 'me' && { authorUpn: loggedInUser?.username }),
    },
  });

  if (messageDeliveries.isError) {
    return <Errorbox queries={[messageDeliveries]} />;
  }

  return (
    <Table
      data={messageDeliveries.data?.items}
      columns={columns}
      ExpandedRowComponent={ExpandedMessageDelivery}
      meta={{ isLoading: messageDeliveries.isPending, noExpandPadding: true, noBorder: true }}
      initialState={{
        pagination: {
          pageIndex: 0,
          pageSize: 10,
        },
        sorting: [
          {
            id: 'messageSentAt',
            desc: true,
          },
        ],
      }}
      state={{
        globalFilter: query.search ?? '',
      }}
      className='rounded-xl'
    />
  );
};
