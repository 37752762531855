import { useBreakpoint } from '@intility/bifrost-react';
import { useTranslation } from 'react-i18next';

import { BetterStepBar, BetterStepBarItem } from '~/components/BetterStepBar';
import { useCurrentStep, type SmsCreationStep } from '~/stores/smsStore';

export const SmsStepbar = () => {
  const { t } = useTranslation('create');
  const toMedium = useBreakpoint(null, 'medium');
  const currentStep = useCurrentStep();

  const stepBarSteps: Array<{ text: string; id: SmsCreationStep }> = [
    {
      text: t('Welcome'),
      id: 'welcome',
    },
    {
      text: t('Select recipient'),
      id: 'recipients',
    },
    {
      text: t('Write message'),
      id: 'write_message',
    },
    {
      text: t('Review'),
      id: 'summary',
    },
    {
      text: t('Completed'),
      id: 'complete',
    },
  ];

  const currentStepIndex = stepBarSteps.findIndex(step => step.id === currentStep);

  return (
    <BetterStepBar>
      {stepBarSteps.map((step, index) => {
        const responsiveText = toMedium ? String(index + 1) : step.text;

        // Completed steps
        if (index < currentStepIndex || currentStep === 'complete') {
          return (
            <BetterStepBarItem
              key={step.id}
              text={step.id === 'complete' ? step.text : responsiveText}
              state='completed'
            />
          );
          // Current step
        } else if (index === currentStepIndex) {
          return <BetterStepBarItem key={step.id} text={step.text} state='active' />;
          // Incomplete steps
        } else {
          return <BetterStepBarItem key={step.id} text={responsiveText} state='neutral' />;
        }
      })}
    </BetterStepBar>
  );
};
