import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { PreviewEstimatedCostBox } from './PreviewEstimatedCostBox';
import { PreviewSelectedRecipientsBox } from './PreviewSelectedRecipientsBox';
import { PreviewSenderBox } from './PreviewSenderBox';

export type TFunctionProp = {
  translate: TFunction;
};

export const PreviewSummaryHeader = () => {
  const { t } = useTranslation('create');
  return (
    <div className='flex items-center justify-between self-stretch border border-bfc-base-dimmed bg-bfc-base-3 md:gap-bfs-24 md:px-bfs-40'>
      <PreviewSenderBox translate={t} />
      <DividerLine />
      <PreviewSelectedRecipientsBox translate={t} />
      <DividerLine />
      <PreviewEstimatedCostBox translate={t} />
    </div>
  );
};

const DividerLine = () => {
  return (
    <svg
      className='h-[122px] w-[2px] md:h-[57px] md:w-[3px] '
      xmlns='http://www.w3.org/2000/svg'
      width='3'
      height='57'
      viewBox='0 0 3 57'
      fill='none'
    >
      <path d='M1.5 56L1.5 1' stroke='#243242' strokeWidth='1.5' strokeLinecap='round' />
    </svg>
  );
};
