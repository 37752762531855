import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Button, Grid } from '@intility/bifrost-react';

import { intilitySms } from '~/assets';
import { GradientBackground } from '~/components/layout/GradientBackground';

export const Unauthorized = () => {
  return (
    <GradientBackground>
      <div className={'m-auto flex h-full max-w-[312px] flex-col justify-center'}>
        <Grid
          className={
            'z-10 justify-items-center rounded-xl border border-bfc-base-dimmed bg-bfc-base-3 p-bfs-24 text-center text-sm shadow-2xl'
          }
          gap={12}
        >
          <img width={80} alt={'Intility SMS'} src={intilitySms} />
          <div className={'grid gap-bfs-8'}>
            <p className={'text-bfc-base-c-2'}>Velkommen til</p>
            <p className={'font-display text-h5 font-h5'}>Intility SMS</p>
            <p className={'text-bfc-base-c-2'}>
              Du har ikke tilgang enda. Forespør dette ved å trykke på knappen under
            </p>
          </div>
          <div className={'w-full px-bfs-16 pt-bfs-12'}>
            <Button className={'w-full'} variant={'filled'} icon={faArrowRight} rightIcon pill>
              <a
                target={'_blank'}
                href='https://my.intility.com/apps?term=intility+sms'
                rel='noreferrer'
              >
                Forespør tilgang
              </a>
            </Button>
            <p className={'mt-bfs-24 text-bfc-base-c-2'}>
              an <b>intility</b> platform service
            </p>
          </div>
        </Grid>
      </div>
    </GradientBackground>
  );
};
