import { Grid, useTheme } from '@intility/bifrost-react';

import { cn } from '~/utils/clsx';

type ContentLayoutProps = {
  children: React.ReactNode;
  pageTitle?: string;
  pageDescription?: string;
  noPadding?: boolean;
  noHeader?: boolean;
  className?: string;
};

export const ContentLayout = ({
  children,
  pageTitle = '',
  pageDescription = '',
  noPadding = false,
  noHeader = false,
  className,
}: ContentLayoutProps) => {
  const { current } = useTheme();

  return (
    <Grid
      gap={40}
      className={cn(
        `bfc-base-3 m-auto flex flex-col bg-contain`,
        !noPadding && 'bfl-page-padding',
        className,
      )}
    >
      {!noHeader && (
        <div
          className={cn(
            'flex flex-col gap-bfs-16 rounded-xl bg-contain bg-left pt-bfs-8 md:p-bfs-40 md:shadow-bf-drop-light',
            current === 'dark' ? 'md:bg-darkHeader' : 'md:bg-lightHeader',
          )}
        >
          <h1 className={'text-h1 font-h1'}>{pageTitle}</h1>
          <p className={'text-base'}>{pageDescription}</p>
        </div>
      )}

      <div
        className={cn(
          'fixed left-0 top-[calc(var(--bf-nav-top-height)_-_0px)] -z-50 h-screen w-screen bg-cover bg-no-repeat opacity-50',
          current === 'dark' ? 'bg-darkBackground' : 'bg-lightBackground',
        )}
      />
      {children}
    </Grid>
  );
};
