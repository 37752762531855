import { createContext, useMemo } from 'react';

import { useAuthorization } from '~/auth/useAuthorization';
import { Unauthorized } from '~/components/errors/Unauthorized';
import { API } from '~/config/apiConfig';

interface ApiAuthorizationContextProps {
  authorizeState: boolean | null;
}

const ApiAuthorizationContext = createContext<ApiAuthorizationContextProps>({
  authorizeState: null,
});

const ApiAuthorizationProvider = ({ children }: { children: React.ReactNode }) => {
  const authorizationState = useAuthorization([API.PortalSms.scope], ['MessageSender']);

  const providerValue = useMemo(
    () => ({
      authorizeState: authorizationState,
    }),
    [authorizationState],
  );

  if (authorizationState === null) return;

  if (!authorizationState) return <Unauthorized />;

  return (
    <ApiAuthorizationContext.Provider value={providerValue}>
      {children}
    </ApiAuthorizationContext.Provider>
  );
};

export { ApiAuthorizationContext, ApiAuthorizationProvider };
