import { Badge } from '@intility/bifrost-react';
import { createColumnHelper } from '@tanstack/react-table';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSmsStoreActions } from '~/stores/smsStore';
import { type ParsedExternalRecipient } from '~/types';
import { ViewOnlyContext } from '../../context/ViewOnlyContext';
import { FailSafeButton } from './FailSafeButton';

const columnHelper = createColumnHelper<ParsedExternalRecipient>();

export const columns = [
  columnHelper.accessor('fullName', {
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Name');
    },
  }),
  columnHelper.accessor('phoneNumber', {
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Phone number');
    },
    cell: function Cell(cell) {
      const phoneNumber = parsePhoneNumber(cell.row.original.phoneNumber);
      return phoneNumber.format('INTERNATIONAL');
    },
  }),
  columnHelper.accessor(row => `${row.streetName}, ${row.cityName}`, {
    id: 'location',
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Place');
    },
    cell: function Cell(cell) {
      const { t } = useTranslation();

      const row = cell.row.original;

      if (row.streetName && row.cityName) {
        return `${row.streetName}, ${row.cityName}`;
      }

      return <Badge state={'warning'}>{t('No registered address')}</Badge>;
    },
  }),
  columnHelper.display({
    id: 'remove',
    cell: function Cell(cell) {
      const { viewOnly } = useContext(ViewOnlyContext);
      const { removeExternalPerson } = useSmsStoreActions();
      const failSafeState = useState(false);

      if (viewOnly) return null;

      const person = cell.row.original;

      return (
        <FailSafeButton
          failSafeState={failSafeState}
          onConfirm={() => removeExternalPerson(person)}
        />
      );
    },
  }),
];
