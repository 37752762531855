import { Button, Grid, Tabs, useFloatingMessage } from '@intility/bifrost-react';
import { useTranslation } from 'react-i18next';

import { useSmsStoreActions } from '~/stores/smsStore';
import { useSelectedRecipients } from '../../hooks/useSelectedRecipients';
import { ActiveRecipientTypeTableContainer } from './ActiveTableContainer';
import { CustomExternalRecipient } from './CustomExternalRecipient';
import { RecipientViewButton } from './RecipientViewButton';

export const RecipientSelectionTabContainer = () => {
  const { t } = useTranslation('create');
  const { showFloatingMessage } = useFloatingMessage();
  const { setCurrentStep, reset } = useSmsStoreActions();
  const { selectedRecipients } = useSelectedRecipients();
  const hasRecipients = selectedRecipients.length;

  return (
    <div className='flex h-full flex-col rounded-xl bg-bfc-base'>
      <div className='rounded-t-xl  bg-bfc-base-3'>
        <div className='p-bfs-24  pb-bfs-16 md:p-bfs-40 md:pb-bfs-16'>
          <h4 className={'font-h4 md:text-xl'}>{t('Recipients')}</h4>
          <p className={'bf-label-description'}>
            {t('Select type and search for recipients, or add recipient manually.')}
          </p>
        </div>
        <Tabs className='bg-bfc-base-3 px-bfs-24 md:p-0' variant='styled'>
          <Tabs.Item
            noPadding
            content={
              <div className='p-bfs-24 md:px-bfs-40 md:pt-bfs-24'>
                <ActiveRecipientTypeTableContainer />
              </div>
            }
          >
            {t('Search')}
          </Tabs.Item>
          <Tabs.Item
            noPadding
            content={
              <div className='p-bfs-24 md:px-bfs-40 md:pt-bfs-24'>
                <CustomExternalRecipient />
              </div>
            }
          >
            {t('Add recipient manually')}
          </Tabs.Item>
        </Tabs>
      </div>
      <Grid
        className='mt-auto rounded-b-xl border-t-2 border-t-bfc-base-dimmed bg-bfc-base-3 px-bfs-24 py-bfs-40 md:px-bfs-40'
        small={2}
      >
        <RecipientViewButton />
        <Grid cols={2} className='sm:place-self-end'>
          <Button
            className={'!rounded-xl !text-bfc-base-c-alert '}
            pill
            onClick={() => {
              reset();
              setCurrentStep('welcome');
            }}
          >
            {t('Cancel')}
          </Button>

          <Button
            variant={'filled'}
            onClick={() => {
              if (hasRecipients) {
                setCurrentStep('write_message');
              } else {
                showFloatingMessage(t('You must select at least one recipient'), {
                  state: 'warning',
                });
              }
            }}
            className={'!rounded-xl'}
            pill
            state={hasRecipients ? 'default' : 'inactive'}
          >
            {t('Next')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
