import { faCircle as faCircleRegular } from '@fortawesome/pro-regular-svg-icons';
import {
  faCircle,
  faCircleCheck,
  faCircleDot,
  faCircleXmark,
  type IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { Grid, Icon } from '@intility/bifrost-react';

// Credit: Provisioner Portal

export const BetterStepBar = ({ children }: React.PropsWithChildren) => {
  return (
    <div className='relative w-full'>
      <div className='absolute bottom-[-1px] right-[unset] h-1 w-full rounded-b-sm rounded-t-sm bg-bfc-base-c-theme md:bottom-[unset] md:right-[17.75px] md:h-full md:w-1' />

      {/* Vertical on medium+ screens*/}
      <Grid gap={112} className='from-medium'>
        {children}
      </Grid>

      {/* Horizontal on medium- screens*/}
      <div className='to-medium flex justify-evenly'>{children}</div>
    </div>
  );
};

export type StepperState = 'active' | 'failed' | 'completed' | 'neutral';

const getIcon = (state: StepperState): IconDefinition => {
  switch (state) {
    case 'active':
      return faCircleDot;
    case 'completed':
      return faCircleCheck;
    case 'failed':
      return faCircleXmark;
    case 'neutral':
      return faCircleRegular;
  }
};

const getBgColor = (state: StepperState): string => {
  switch (state) {
    case 'failed':
      return 'var(--bfc-alert-c)';
    default:
      return 'var(--bfc-theme-c)';
  }
};

const getColor = (state: StepperState): string => {
  switch (state) {
    case 'failed':
      return 'var(--bfc-alert)';
    default:
      return 'var(--bfc-theme)';
  }
};

export const BetterStepBarItem = ({ text, state }: { text: string; state: StepperState }) => {
  return (
    <div className='flex flex-col items-center justify-end gap-bfs-24 md:flex-row md:gap-bfs-24'>
      <p className='text-sm font-normal'>{text}</p>
      <div className='relative flex items-center justify-center md:w-10 [&>*]:absolute [&>*]:w-full'>
        <Icon size='xl' fixedWidth color={getBgColor(state)} icon={faCircle} />
        <Icon size='xl' fixedWidth color={getColor(state)} icon={getIcon(state)} />
      </div>
    </div>
  );
};
