import { Checkbox } from '@intility/bifrost-react';
import type { GraphUser } from '@intility/portal-sms-shared-utils';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { useRecipients, useSmsStoreActions } from '~/stores/smsStore';
import { cleanName, formatPhoneNumber } from '~/utils/format';

const columnHelper = createColumnHelper<GraphUser>();

export const companyMemberColumns = [
  columnHelper.accessor(row => cleanName(row.displayName), {
    id: 'displayName',
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Name');
    },
    meta: {
      className: 'max-w-[100px] break-words',
    },
  }),
  columnHelper.accessor('mobilePhone', {
    enableSorting: false,
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Phone number');
    },
    cell: function Cell(cell) {
      const { t } = useTranslation('create');

      return cell.row.original.mobilePhone
        ? formatPhoneNumber(cell.row.original.mobilePhone)
        : t('No phone number registered');
    },
  }),
  columnHelper.display({
    id: 'isSelected',
    enableSorting: false,
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Added');
    },
    cell: function Cell(cell) {
      const { addGraphUser, removeGraphUser } = useSmsStoreActions();

      const recipients = useRecipients();
      const graphUser = cell.row.original;
      const phoneNumber = graphUser.mobilePhone;
      const recipient = phoneNumber ? recipients.get(phoneNumber) : undefined;

      const isChecked = recipient ? recipient.isSelected : false;

      const handleOnToggle = () => {
        if (isChecked) {
          removeGraphUser(graphUser);
        } else {
          addGraphUser(graphUser);
        }
      };

      return (
        <div className={'flex items-center'}>
          <Checkbox
            label={''}
            hideLabel
            checked={isChecked}
            onChange={handleOnToggle}
            type='switch'
          />
        </div>
      );
    },
  }),
];
