import type { GraphUser } from '@intility/portal-sms-shared-utils';
import { useQuery } from '@tanstack/react-query';

import { api } from '~/auth/authorizedFetch';
import { API } from '~/config/apiConfig';
import { QueryKey } from '~/types';

export type GraphUsersParams = {
  displayName?: string;
};

const getGraphUsers = async ({ queryParams }: { queryParams: GraphUsersParams }) => {
  const endpoint = `${API.PortalSms.url}/v1/graph/users`;

  const result = await api.get(endpoint, { searchParams: queryParams }).json<{
    '@odata.context': string;
    value: GraphUser[];
  }>();

  const usersWithPhoneNumbers = result.value.filter(user => Boolean(user.mobilePhone));

  return usersWithPhoneNumbers;
};
export const useGraphUsers = ({ queryParams }: { queryParams: GraphUsersParams }) => {
  return useQuery({
    queryKey: [QueryKey.GraphUsers, queryParams],
    queryFn: () => getGraphUsers({ queryParams }),
    enabled: !!queryParams.displayName,
  });
};
