import { useQuery } from '@tanstack/react-query';
import { type GraphGroup } from '@intility/portal-sms-shared-utils';

import { api } from '~/auth/authorizedFetch';
import { API } from '~/config/apiConfig';
import { QueryKey } from '~/types';

export type GraphGroupsQueryParams = {
  displayName?: string;
};
const getGraphGroups = async ({ queryParams }: { queryParams: GraphGroupsQueryParams }) => {
  const endpoint = `${API.PortalSms.url}/v1/graph/groups`;

  const result = await api
    .get(endpoint, {
      searchParams: queryParams,
    })
    .json<{
      '@odata.context': string;
      value: GraphGroup[];
    }>();

  return result.value;
};
export const useGraphGroups = ({ queryParams }: { queryParams: GraphGroupsQueryParams }) => {
  return useQuery({
    queryKey: [QueryKey.GraphGroups, queryParams],
    queryFn: () => getGraphGroups({ queryParams }),
    enabled: !!queryParams.displayName,
  });
};
