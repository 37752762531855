import type { PersonDto } from '@intility/portal-sms-shared-utils';
import { useQuery } from '@tanstack/react-query';

import { api } from '~/auth/authorizedFetch';
import { API } from '~/config/apiConfig';
import { type Paginated, type ParsedExternalRecipient, QueryKey } from '~/types';

export type ExternalRecipientsParams = {
  query: string;
  page?: number;
  limit?: number;
};

const getExternalRecipients = async ({
  queryParams,
}: {
  queryParams: ExternalRecipientsParams;
}) => {
  const endpoint = `${API.PortalSms.url}/opplysningen-1881/search/`;

  const result = await api
    .get(endpoint, { searchParams: queryParams })
    .json<Paginated<PersonDto>>();
  const persons = result.items.reduce((accumulator: ParsedExternalRecipient[], item) => {
    item.phoneNumbers.forEach(phoneNumber => {
      accumulator.push({
        id: item.id,
        fullName: item.fullName ?? '',
        phoneNumber: phoneNumber,
        streetName: item.streetName ?? '',
        cityName: item.cityName ?? '',
      });
    });

    return accumulator;
  }, []);
  return {
    totalItems: result.totalItems,
    items: persons,
  };
};

export const useExternalRecipients = ({
  queryParams,
}: {
  queryParams: ExternalRecipientsParams;
}) => {
  return useQuery({
    queryKey: [QueryKey.ExternalRecipients, queryParams],
    queryFn: () => getExternalRecipients({ queryParams }),
    enabled: !!queryParams.query,
  });
};
