import { createContext } from 'react';

interface ViewOnlyContextProps {
  viewOnly: boolean;
}

const ViewOnlyContext = createContext<ViewOnlyContextProps>({
  viewOnly: false,
});

const ViewOnlyProvider = ({
  viewOnly,
  children,
}: {
  viewOnly: boolean;
  children: React.ReactNode;
}) => {
  return (
    <ViewOnlyContext.Provider
      value={{
        viewOnly: viewOnly,
      }}
    >
      {children}
    </ViewOnlyContext.Provider>
  );
};

export { ViewOnlyContext, ViewOnlyProvider };
