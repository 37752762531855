import { Badge, Checkbox, Spinner } from '@intility/bifrost-react';
import type { GraphGroup } from '@intility/portal-sms-shared-utils';
import { createColumnHelper } from '@tanstack/react-table';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerContext } from '~/context/DrawerContext';
import { useSelectedGraphGroups, useSmsStoreActions } from '~/stores/smsStore';
import { useGraphGroupMembers } from '../../api/getGraphGroupMembers';
import { EditGroupRecipientDrawer } from './EditGroupRecipientDrawer';

const columnHelper = createColumnHelper<GraphGroup>();

export const groupColumns = [
  columnHelper.accessor('displayName', {
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Group');
    },
    meta: {
      className: 'break-all',
    },
  }),
  columnHelper.display({
    id: `groupMembers`,
    cell: function Cell(props) {
      const groupId = props.cell.row.original.id;

      const { data, isPending } = useGraphGroupMembers({ groupId: groupId });

      if (isPending) return <Spinner size={14} />;

      return data?.length ?? 0;
    },
    header: function Header() {
      const { t } = useTranslation('create');
      return t('Members');
    },
  }),
  columnHelper.display({
    id: 'isSelected',
    enableSorting: false,
    header: function Header() {
      const { t } = useTranslation('create');

      return t('Added');
    },
    meta: {
      // className: 'md:w-[300px]',
    },
    cell: function Cell(cell) {
      const { t } = useTranslation('create');
      const { handleDrawer } = useContext(DrawerContext);
      const { addGraphGroup, removeGraphGroup } = useSmsStoreActions();
      const selectedGraphGroups = useSelectedGraphGroups();

      const group = cell.row.original;
      const isChecked = selectedGraphGroups.some(selectedGroup => selectedGroup.id === group.id);

      const groupMembers = useGraphGroupMembers({
        groupId: group.id,
        options: { enabled: Boolean(group.id) },
      });

      if (groupMembers.isPending) return <Spinner size={14} />;
      if (groupMembers.isError) return <Badge state='warning'>{t('An error occurred')}</Badge>;

      const groupMembersWithPhoneNumbers = groupMembers.data.filter(
        member => member.mobilePhone !== null,
      );

      const groupWithMembers = { ...group, members: groupMembersWithPhoneNumbers };

      const handleOnChange = () => {
        if (isChecked) {
          removeGraphGroup(group.id);
        } else {
          addGraphGroup(groupWithMembers);
        }
      };

      if (!groupMembersWithPhoneNumbers.length) {
        return <Badge state={'warning'}>{t('No members with phone numbers')}</Badge>;
      }

      return (
        <div
          className={'ml-auto flex flex-col items-start sm:flex-row sm:items-center sm:gap-bfs-16'}
        >
          <Checkbox
            label={''}
            hideLabel
            type='switch'
            checked={isChecked}
            onChange={() => handleOnChange()}
          />

          {isChecked && (
            <button
              onClick={() =>
                handleDrawer({
                  content: <EditGroupRecipientDrawer groupWithMembers={groupWithMembers} />,
                  header: cell.row.original.displayName ?? '',
                })
              }
              className={'bf-link'}
            >
              <p className={'to-small'}>{t('Edit')}</p>
              <p className={'from-small'}>{t('Edit members')}</p>
            </button>
          )}
        </div>
      );
    },
  }),
];
