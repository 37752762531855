import { useState } from 'react';

import { CompanyGroupTable } from './CompanyGroupTable';
import { CompanyUserTable } from './CompanyUserTable';
import { ExternalRecipientTable } from './ExternalRecipientTable';

export const TABLE_FILTER = { GROUP: 'GROUP', INTERNAL: 'INTERNAL', EXTERNAL: 'EXTERNAL' } as const;
export type TableFilter = keyof typeof TABLE_FILTER;

export const ActiveRecipientTypeTableContainer = () => {
  const [activeFilter, setActiveFilter] = useState<TableFilter>('GROUP');

  switch (activeFilter) {
    case 'GROUP':
      return (
        <CompanyGroupTable
          activeTableFilter={activeFilter}
          handleOnFilterChange={filter => setActiveFilter(filter)}
        />
      );
    case 'INTERNAL':
      return (
        <CompanyUserTable
          activeTableFilter={activeFilter}
          handleOnFilterChange={filter => setActiveFilter(filter)}
        />
      );
    case 'EXTERNAL':
      return (
        <ExternalRecipientTable
          activeTableFilter={activeFilter}
          handleOnFilterChange={filter => setActiveFilter(filter)}
        />
      );
    default:
      return null;
  }
};
