import type { GraphGroup, GraphUser } from '@intility/portal-sms-shared-utils';
import { create } from 'zustand';

import type { ParsedExternalRecipient } from '~/types';

type Recipient = {
  name: string;
  phoneNumber: string;
  isSelected: boolean;
  isExternal?: boolean;
  streetAddress?: string;
  city?: string;
};

export type GraphGroupWithMembers = GraphGroup & {
  members: GraphUser[];
};

type SmsCreationStep = 'welcome' | 'recipients' | 'write_message' | 'summary' | 'complete';

interface State {
  currentStep: SmsCreationStep;
  selectedGraphGroups: GraphGroupWithMembers[];
  selectedGraphUsers: GraphUser[];
  selectedExternalPeople: ParsedExternalRecipient[];
  recipients: Map<string, Recipient>;
  messageBody: string;
  actions: {
    setCurrentStep: (newStep: SmsCreationStep) => void;
    setMessageBody: (newMessageBody: string) => void;
    addGraphGroup: (newGroup: GraphGroupWithMembers) => void;
    removeGraphGroup: (groupId: string) => void;
    addGraphUser: (newUser: GraphUser) => void;
    removeGraphUser: (graphUser: GraphUser) => void;
    addExternalPerson: (newPerson: ParsedExternalRecipient) => void;
    removeExternalPerson: (person: ParsedExternalRecipient) => void;
    reset(): void;
  };
}

const initialState = {
  selectedGraphGroups: [],
  selectedGraphUsers: [],
  selectedExternalPeople: [],
  recipients: new Map(),
  messageBody: '',
};

const smsStore = create<State>()((set, get) => ({
  currentStep: 'welcome' as const,
  ...initialState,
  actions: {
    setCurrentStep: newStep => set({ currentStep: newStep }),
    setMessageBody: newMessageBody =>
      set({
        messageBody: newMessageBody,
      }),
    addGraphGroup: newGroup =>
      set(state => {
        if (state.selectedGraphGroups.some(group => group.id === newGroup.id)) {
          return state;
        }

        newGroup.members.forEach(member => {
          if (!member.mobilePhone) return;

          addRecipients([
            {
              name: member.displayName,
              phoneNumber: member.mobilePhone,
              isSelected: true,
            },
          ]);
        });

        return {
          selectedGraphGroups: [...state.selectedGraphGroups, newGroup],
        };
      }),
    removeGraphGroup: groupId =>
      set(state => {
        const group = state.selectedGraphGroups.find(group => group.id === groupId);
        const selectedGraphUsers = state.selectedGraphUsers;
        const otherGroups = state.selectedGraphGroups.filter(group => group.id !== groupId);

        group?.members.forEach(groupMember => {
          if (!groupMember.mobilePhone) return;

          const selectedGraphUser = selectedGraphUsers.find(
            graphUser => graphUser.mobilePhone === groupMember.mobilePhone,
          );

          // If the group member has been selected from the "People" tab, we leave him as is
          if (selectedGraphUser) {
            return;
          } else {
            const existsInOtherGroup = otherGroups.some(group => {
              const isGroupMember = group.members.some(
                otherGroupMember => otherGroupMember.id === groupMember.id,
              );

              return isGroupMember;
            });

            // If the group member exists in another group, we leave him as is
            if (existsInOtherGroup) return;

            removeRecipients([groupMember.mobilePhone]);
          }
        });

        return {
          selectedGraphGroups: otherGroups,
        };
      }),
    addGraphUser: newGraphUser =>
      set(state => {
        if (
          state.selectedGraphUsers.some(
            graphUser => graphUser.displayName === newGraphUser.displayName,
          )
        ) {
          return state;
        }

        if (!newGraphUser.mobilePhone) return state;

        addRecipients([
          {
            name: newGraphUser.displayName,
            phoneNumber: newGraphUser.mobilePhone,
            isSelected: true,
          },
        ]);

        return {
          selectedGraphUsers: [...state.selectedGraphUsers, newGraphUser],
        };
      }),
    removeGraphUser: graphUser =>
      set(state => {
        const remainingGraphUsers = state.selectedGraphUsers.filter(
          person => person.id !== graphUser.id,
        );

        const recipient = state.recipients.get(graphUser.mobilePhone ?? '');

        if (recipient) {
          removeRecipients([recipient.phoneNumber]);
        }

        return {
          selectedGraphUsers: remainingGraphUsers,
        };
      }),
    addExternalPerson: newPerson =>
      set(state => {
        if (
          state.selectedExternalPeople.some(person => person.phoneNumber === newPerson.phoneNumber)
        ) {
          return state;
        }

        if (!newPerson.phoneNumber) return state;

        addRecipients([
          {
            name: newPerson.fullName ?? '',
            phoneNumber: newPerson.phoneNumber,
            isSelected: true,
            isExternal: true,
            streetAddress: newPerson.streetName ?? '',
            city: newPerson.cityName ?? '',
          },
        ]);

        return {
          selectedExternalPeople: [...state.selectedExternalPeople, newPerson],
        };
      }),
    removeExternalPerson: person =>
      set(state => {
        const remainingPeople = state.selectedExternalPeople.filter(
          selectedPerson => selectedPerson.id !== person.id,
        );

        const recipient = state.recipients.get(person.phoneNumber);

        if (recipient) {
          removeRecipients([recipient.phoneNumber]);
        }

        return {
          selectedExternalPeople: remainingPeople,
        };
      }),
    reset: () => {
      set(initialState);
    },
  },
}));

const addRecipients = (recipients: Recipient[]) => {
  smsStore.setState(prev => {
    const updatedRecipients = new Map(prev.recipients);

    recipients.forEach(recipient => {
      updatedRecipients.set(recipient.phoneNumber, recipient);
    });

    return {
      recipients: updatedRecipients,
    };
  });
};

const removeRecipients = (phoneNumbers: string[]) => {
  smsStore.setState(prev => {
    const updatedRecipients = new Map(prev.recipients);

    phoneNumbers.forEach(phoneNumber => {
      const existingRecipient = updatedRecipients.get(phoneNumber);

      if (!existingRecipient) return;

      updatedRecipients.set(existingRecipient.phoneNumber, {
        ...existingRecipient,
        isSelected: false,
      });
    });

    return {
      recipients: updatedRecipients,
    };
  });
};

export const useCurrentStep = () => smsStore(state => state.currentStep);
export const useMessageBody = () => smsStore(state => state.messageBody);
export const useRecipients = () => smsStore(state => state.recipients);
export const useSelectedGraphGroups = () => smsStore(state => state.selectedGraphGroups);
export const useSelectedGraphUsers = () => smsStore(state => state.selectedGraphUsers);
export const useSelectedExternalPeople = () => smsStore(state => state.selectedExternalPeople);

export const useSmsStoreActions = () => smsStore(state => state.actions);

export { addRecipients, removeRecipients, type Recipient, type SmsCreationStep };
